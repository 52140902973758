import { useMemo, useState } from 'react';

export type ModalControler<ModalName extends string = ''> = {
  [K in `is${ModalName}Open`]: boolean;
} & {
  [K in `on${ModalName}Open`]: () => void;
} & {
  [K in `on${ModalName}Close`]: () => void;
};

export const useModalController = <ModalName extends string = ''>(
  modalName: ModalName = '' as ModalName,
): ModalControler<ModalName> => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = (): void => setIsOpen(false);

  const onOpen = (): void => setIsOpen(true);

  return useMemo(() => {
    return {
      [`is${modalName}Open`]: isOpen,
      [`on${modalName}Open`]: onOpen,
      [`on${modalName}Close`]: onClose,
    } as ModalControler<ModalName>;
  }, [isOpen]);
};
