import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import { ResendCode, ResendTimer, SentCodeErrorSection } from './components';
import { usePhoneVerificationViewModel } from './usePhoneVerificationViewModel';

import styles from './styles.module.scss';

export const PhoneVerification: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'phoneVerification' });
  const {
    isVerifying,
    phone,
    secondsLeft,
    code,
    hasCodeError,
    handleCodeResend,
    handleCodeVerify,
    handleCodeChange,
    codeResendDisabled,
    codeResendVisible,
    codeVerificationDisabled,
    verificationState,
  } = usePhoneVerificationViewModel();

  return (
    <Box className={styles.wrapper}>
      <Typography variant="h1" className={styles.title}>
        {t('title')}
      </Typography>
      <Box>
        <Typography variant="subtitle1" className={styles.subtitle}>
          {t('description', { phone })}
        </Typography>
      </Box>
      <Box
        className={styles.inputWrapper}
        component={'form'}
        onSubmit={(e): void => {
          e.preventDefault();
          handleCodeVerify();
        }}
      >
        <TextField
          placeholder={t('inputPlaceholder')}
          type="number"
          inputMode="numeric"
          autoComplete="one-time-code"
          onChange={handleCodeChange}
          value={code}
          className={clsx(styles.inputRoot, { [styles.inputError]: hasCodeError })}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={codeVerificationDisabled}
          className={styles.verifyButton}
        >
          {isVerifying ? (
            <CircularProgress size="22px" className={styles.progress} />
          ) : (
            t('submitButton')
          )}
        </Button>
      </Box>
      <Stack spacing="25px" alignItems="center" paddingTop="25px">
        {codeResendVisible ? (
          <ResendCode onResendCode={handleCodeResend} disabled={codeResendDisabled} />
        ) : null}
        <ResendTimer secondsLeft={secondsLeft} />
        <SentCodeErrorSection
          verificationState={verificationState}
          secondsLeft={secondsLeft}
        />
      </Stack>
    </Box>
  );
};
