import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import {
  ACCOUNT_TYPES,
  AUTH_TYPES,
  ONBOARDING_TYPES,
  STORAGE_TYPES,
  SYNC_TYPES,
} from '@/ioc/types';

import { COUNTRIES_DC, IAccountRepository } from '@/features/common/account';
import {
  AuthStatus,
  IAuthRepository,
  InvalidPhoneCodeError,
  InvalidWorkEmailError,
  NonUniquePhoneNumberError,
  PhoneCodeSendPermanentRateLimitError,
  PhoneCodeSendRateLimitError,
  UserAlreadyExistsError,
} from '@/features/common/auth';
import { IOnboardingUseCase } from '@/features/common/onboarding';
import { NetworkError } from '@/features/system/network';
import { IStorageRepository } from '@/features/system/storage/index';
import { IBaseSyncRepository } from '@/features/system/sync';

import { WorkEmailValidationSchema } from '@/utils/validation';

type FullFillUserDataParams = {
  displayName: string;
  phoneNumber: string;
  unconfirmedEmail?: string;
  country: COUNTRIES_DC;
};

type SetupAccountParams = {
  info: FullFillUserDataParams;
  credentials?: {
    email: string;
    password: string;
  };
};

export interface ISignUpUseCases {
  signUpGoogle(): Promise<void>;
  signUpMicrosoft(): Promise<void>;
  signUpWithEmail(email: string): Promise<void>;
  sendEmailVerification(email: string): Promise<void>;
  verificateEmail(params: { code: string; email: string }): Promise<void>;
  setupAccount(params: SetupAccountParams): Promise<void>;
  checkUserExists(email: string): Promise<boolean>;
  getUserCountry(): Observable<string>;
  sendPhoneVerificationCode(): Promise<{ sent: boolean; nextAttemptIn: number | null }>;
  verifySmsCode(code: string): Promise<boolean>;
  getSmsResendTime(): Observable<Nullable<Date>>;
}

@injectable()
export class SignUpUseCases implements ISignUpUseCases {
  handleSuccessRedirects(): Observable<void> {
    throw new Error('Method not implemented.');
  }

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(STORAGE_TYPES.StorageRepository)
  private storage: IStorageRepository;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private baseSyncRepository: IBaseSyncRepository;

  @inject(ONBOARDING_TYPES.OnboardingUseCase)
  private onboardingUseCase: IOnboardingUseCase;

  private async completeOnboardingSteps(): Promise<void> {
    try {
      await this.onboardingUseCase.completeSignupStep();
      await this.onboardingUseCase.completeMobileSignupOpenChromeStoreStep();
    } catch (e) {
      console.error(e);
    }
  }

  getUserCountry(): Observable<string> {
    return this.authRepository.getUserCountry();
  }

  private async sendSetupAccount(account: {
    fullName: string;
    phone: string;
    country: COUNTRIES_DC;
  }): Promise<void> {
    const { settings } = await this.accountRepository.setupAccount(account);

    if (settings.phoneVerificationRequired && !settings.phoneVerified) {
      this.sendPhoneVerificationCode().catch();
    }
  }

  async setupAccount({ credentials, info }: SetupAccountParams): Promise<void> {
    try {
      this.baseSyncRepository.pause();

      const authStatus = await firstValueFrom(this.authRepository.getAuthStatus());

      /*
        in case of unregistered user, we need to check if phone number is unique before sign up,
        cause firebase auth will trigger automatic db creation with screen loader that will break
        validation flow of phone number
      */
      if (authStatus !== AuthStatus.Authorized) {
        const isPhoneValid = await this.authRepository.validatePhone(info.phoneNumber);
        if (!isPhoneValid) throw new NonUniquePhoneNumberError();
      }

      if (credentials) {
        const isExist = await this.authRepository.checkUserExists(credentials.email);
        if (isExist) {
          throw new UserAlreadyExistsError();
        }
        await this.authRepository.signUpWithEmailAndPassword(
          credentials.email,
          credentials.password,
        );

        await this.sendSetupAccount({
          fullName: info.displayName,
          phone: info.phoneNumber,
          country: info.country,
        });

        this.accountRepository.deleteEmailForAccountCreation();
        this.storage.save({ hasSignedUp: 'true' });
      } else {
        await this.sendSetupAccount({
          fullName: info.displayName,
          phone: info.phoneNumber,
          country: info.country,
        });
        this.accountRepository.deleteEmailForAccountCreation();
        await this.completeOnboardingSteps();
      }
    } catch (error) {
      throw error;
    } finally {
      this.baseSyncRepository.unpause();
    }
  }

  async signUpGoogle(): Promise<void> {
    await this.authRepository.signUpWithGoogle();
    this.storage.save({ hasSignedUp: 'true' });
  }

  async signUpWithEmail(email: string): Promise<void> {
    const isExist = await this.authRepository.checkUserExists(email);
    if (isExist) {
      throw new UserAlreadyExistsError();
    }
    await WorkEmailValidationSchema.validate(email).catch(() => {
      throw new InvalidWorkEmailError();
    });
    this.accountRepository.saveEmailForAccountCreation(email);
  }

  async verificateEmail(params: { code: string; email: string }): Promise<void> {
    await this.authRepository.applyActionCode(params.code);
    await this.accountRepository.syncAccount(params.email);

    const authStatus = await firstValueFrom(this.authRepository.getAuthStatus());

    if (authStatus === AuthStatus.Authorized) {
      await this.onboardingUseCase.completeSignupStep();
    }
  }

  async signUpMicrosoft(): Promise<void> {
    await this.authRepository.signUpWithMicrosoft();
    this.storage.save({ hasSignedUp: 'true' });
  }

  checkUserExists(email: string): Promise<boolean> {
    return this.authRepository.checkUserExists(email);
  }

  async sendEmailVerification(email: string): Promise<void> {
    await this.authRepository.sendVerificationEmail(email);
  }

  async sendPhoneVerificationCode(): Promise<{
    sent: boolean;
    nextAttemptIn: number | null;
  }> {
    try {
      const res = await this.authRepository.sendPhoneVerificationCode();

      if (res.nextAttemptIn) {
        this.setSmsResendTime(new Date(new Date().getTime() + res.nextAttemptIn * 1000));
      }

      return res;
    } catch (error) {
      if (error instanceof NetworkError) {
        switch (error.statusCode) {
          case 429:
            throw new PhoneCodeSendPermanentRateLimitError();
          case 400:
            throw new PhoneCodeSendRateLimitError();
          default:
            throw error;
        }
      }

      throw error;
    }
  }

  async verifySmsCode(code: string): Promise<boolean> {
    return this.authRepository.verifySmsCode(code).catch((error) => {
      switch (error.statusCode) {
        case 400:
          throw new InvalidPhoneCodeError();
        default:
          throw error;
      }
    });
  }

  private static readonly VERIFY_SMS_KEY = 'pw-endLockPhoneVerificationLeft';

  getSmsResendTime(): Observable<Nullable<Date>> {
    return this.storage.get$(SignUpUseCases.VERIFY_SMS_KEY).pipe(
      map((time) => {
        if (!time) return null;
        const date = new Date(+time);
        if (Number.isNaN(date.getTime())) return null;
        if (date.getTime() < new Date().getTime()) return null;
        return date;
      }),
    );
  }

  private setSmsResendTime(date: Date): void {
    this.storage.save({ [SignUpUseCases.VERIFY_SMS_KEY]: date.getTime() });
  }
}
