import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { IContactListUseCase } from '@/features/common/contactList';
import { IContactListEntityUI } from '@/features/common/contactList';
import { useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

export const useEditFieldAction = (): {
  handleEditRow: (newRow: IContactListEntityUI, oldRow: IContactListEntityUI) => void;
} => {
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );
  const { trackRenameList } = useAnalytics();
  const logger = useAppLogger();

  const onEditNameField = async (id: string, updatedName: string): Promise<void> => {
    trackRenameList();
    try {
      await contactListUseCase.updateContactList({
        uuid: id,
        name: updatedName,
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const handleEditRow = (
    newRow: IContactListEntityUI,
    oldRow: IContactListEntityUI,
  ): IContactListEntityUI => {
    if (oldRow.name !== newRow.name) {
      onEditNameField(newRow.id, newRow.name);
    }
    return newRow;
  };

  return {
    handleEditRow,
  };
};
