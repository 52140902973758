import { useTeamMemberSelect, useTeamMemberSelectOptions } from '@/features/settings';
import { useAnalytics } from '@/features/system/analytics';

type Return = {
  handleUsersChange: (value: string) => void;
  selectedUser: string;
};

export const useMembersSelectFacade = (): Return => {
  const { trackFilterByUsers } = useAnalytics();
  const options = useTeamMemberSelectOptions();
  const { value: filterByUser, setValue: setFilterByUser } = useTeamMemberSelect(
    options[0] ? options[0].value : '',
  );

  const handleUsersChange = (value: string): void => {
    trackFilterByUsers('dashboard');
    setFilterByUser(value);
  };

  return {
    selectedUser: filterByUser,
    handleUsersChange,
  };
};
