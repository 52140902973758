export const APP_CONFIG_TYPES = {
  AppConfig: Symbol.for('AppConfig'),
};

export const AUTH_TYPES = {
  AuthApiService: Symbol.for('AuthApiService'),
  AuthRepository: Symbol.for('AuthRepository'),
  FirebaseService: Symbol.for('FirebaseService'),
  AuthUseCase: Symbol.for('AuthUseCase'),
  AuthWatcherUseCase: Symbol.for('AuthWatcherUseCase'),
};

export const ACCOUNT_TYPES = {
  AccountDao: Symbol.for('AccountDao'),
  AccountApiService: Symbol.for('AccountApiService'),
  AccountRepository: Symbol.for('AccountRepository'),
  AccountUseCase: Symbol.for('AccountUseCase'),
  AccountReplicationService: Symbol.for('AccountReplicationService'),
  AccountPermissionsUseCase: Symbol.for('AccountPermissionsUseCase'),
  AccountMigrationService: Symbol.for('AccountMigrationService'),
};

export const BILLING_TYPES = {
  BillingUseCase: Symbol.for('BillingUseCase'),
  BillingRepository: Symbol.for('BillingRepository'),
  BillingApiService: Symbol.for('BillingApiService'),
  BillingConfig: Symbol.for('BillingConfig'),
  BillingState: Symbol.for('BillingState'),
};

export const PAYMENT_DETAILS_TYPES = {
  PaymentDetailsApiService: Symbol.for('PaymentDetailsApiService'),
  PaymentDetailsRepository: Symbol.for('PaymentDetailsRepository'),
  PaymentDetailsUseCase: Symbol.for('PaymentDetailsUseCase'),
};

export const COMPANY_TYPES = {
  CompanyUseCase: Symbol.for('CompanyUseCase'),
  CompanyRepository: Symbol.for('CompanyRepositoryNew'),
  CompanyDao: Symbol.for('CompanyDao'),
  CompanyReplicationService: Symbol.for('CompanyReplicationService'),
};

export const CONTACT_TYPES = {
  ContactUseCase: Symbol.for('ContactUseCase'),
  ContactRepository: Symbol.for('ContactRepositoryNew'),
  ContactApiService: Symbol.for('ContactApiService'),
  ContactDao: Symbol.for('ContactDao'),
  ContactReplicationService: Symbol.for('ContactReplicationService'),
  ContactSyncRepository: Symbol.for('ContactSyncRepository'),
  ContactSyncUseCase: Symbol.for('ContactSyncUseCase'),
};

export const CONTACT_BY_FILTERS_TYPES = {
  ContactByFiltersUseCase: Symbol.for('ContactByFiltersUseCase'),
  ContactByFiltersRepository: Symbol.for('ContactByFiltersRepository'),
  ContactByFiltersDao: Symbol.for('ContactByFiltersDao'),
};

export const CONTACT_LIST_TYPES = {
  ContactListUseCase: Symbol.for('ContactListUseCase'),
  ContactListRepository: Symbol.for('ContactListRepository'),
  ContactListApiService: Symbol.for('ContactListApiService'),
  ContactListDao: Symbol.for('ContactListDao'),
  ContactListReplicationService: Symbol.for('ContactListReplicationService'),
};

export const EXTENSION_TYPES = {
  ExtensionRepository: Symbol('ExtensionRepository'),
  ExtensionUseCase: Symbol('ExtensionUseCase'),
};

export const TAG_TYPES = {
  TagUseCase: Symbol.for('TagUseCase'),
  TagRepository: Symbol.for('TagRepositoryNew'),
  TagApiService: Symbol.for('TagApiService'),
  TagDao: Symbol.for('TagDao'),
  TagReplicationService: Symbol.for('TagReplicationService'),
};

export const WORKSPACE_TYPES = {
  WorkspaceDao: Symbol.for('WorkspaceDao'),
  WorkspaceApiService: Symbol.for('WorkspaceApiService'),
  WorkspaceRepository: Symbol.for('WorkspaceRepository'),
  WorkspaceUseCase: Symbol.for('WorkspaceUseCase'),
  SubscriptionUseCase: Symbol.for('SubscriptionUseCase'),
  WorkspaceReplicationService: Symbol.for('WorkspaceReplicationService'),
  WorkspaceMigrationService: Symbol.for('WorkspaceMigrationService'),
  CreditUseCase: Symbol.for('CreditUseCase'),
};

export const ENRICHMENT_TYPES = {
  EnrichmentDao: Symbol.for('EnrichmentDao'),
  EnrichmentRepository: Symbol.for('EnrichmentRepository'),
  EnrichmentUseCase: Symbol.for('EnrichmentUseCase'),
  EnrichmentReportUseCase: Symbol.for('EnrichmentReportUseCase'),
  EnrichmentFileUploadUseCase: Symbol.for('EnrichmentFileUploadUseCase'),
  EnrichmentApiService: Symbol.for('EnrichmentApiService'),
  EnrichmentReplicationService: Symbol.for('EnrichmentReplicationService'),
  EnrichmentMigrationService: Symbol.for('EnrichmentMigrationService'),
};

export const ANALYTICS_TYPES = {
  AnalyticsRepository: Symbol.for('AnalyticsRepository'),
  AmplitudeRepository: Symbol.for('AmplitudeRepository'),
  GoogleTagManagerRepository: Symbol.for('GoogleTagManagerRepository'),
  FacebookPixelRepository: Symbol.for('FacebookPixelRepository'),
  AnalyticsUseCase: Symbol.for('AnalyticsUseCase'),
};

export const PROSPECT_TASK_TYPES = {
  ProspectTaskRepository: Symbol.for('ProspectTaskRepository'),
  ProspectTaskUseCase: Symbol.for('ProspectTaskUseCase'),
  ProspectTaskApiService: Symbol.for('ProspectTaskApiService'),
};

export const SYNC_TYPES = {
  SseClient: Symbol.for('SseClient'),
  BaseSyncService: Symbol.for('BaseSyncService'),
  ResumeTokenManager: Symbol.for('ResumeTokenManager'),
  BaseSyncRepository: Symbol.for('BaseSyncRepository'),
  ContactSyncRepository: Symbol.for('ContactSyncRepository'),
  BaseSyncUseCase: Symbol.for('BaseSyncUseCase'),
  ContactSyncUseCase: Symbol.for('ContactSyncUseCase'),
};

export const DB_TYPES = {
  DbManager: Symbol.for('DbManager'),
  DbCollection: Symbol.for('DbCollection'),
  DbGarbageCollector: Symbol.for('DbGarbageCollector'),
  DbContactCollection: Symbol.for('DbContactCollection'),
  DbCollectionCreator: Symbol.for('DbCollectionCreator'),
  DbUseCase: Symbol.for('DbUseCase'),
};

export const REPLICATION_TYPES = {
  ReplicationRepository: Symbol.for('ReplicationRepository'),
  ReplicationUseCase: Symbol.for('ReplicationUseCase'),
};

export const HELP_CENTER_TYPES = {
  HelpCenterRepository: Symbol.for('HelpCenterRepository'),
  HelpCenterUseCase: Symbol.for('HelpCenterUseCase'),
};

export const GLOBAL_ERROR_TYPES = {
  GlobalErrorUseCase: Symbol.for('GlobalErrorUseCase'),
  GlobalErrorRepository: Symbol.for('GlobalErrorRepository'),
};

export const FEATURE_FLAG_TYPES = {
  FeatureFlagManager: Symbol.for('FeatureFlagManager'),
};

export const REFERRAL_TYPES = {
  ReferralApiService: Symbol.for('ReferralApiService'),
  ReferralRepository: Symbol.for('ReferralRepository'),
  ReferralUseCase: Symbol.for('ReferralUseCase'),
  ReferralReplicationService: Symbol.for('ReferralReplicationService'),
  ReferralDao: Symbol.for('ReferralDao'),
};

export const APP_LOGGER_TYPES = {
  AppLogger: Symbol.for('AppLogger'),
};

export const LEADS_TRACKING_TYPES = {
  LeadsTrackingRepository: Symbol.for('LeadsTrackingRepository'),
  LeadsTrackingUseCase: Symbol.for('LeadsTrackingUseCase'),
};

export const STORAGE_TYPES = {
  StorageRepository: Symbol.for('StorageRepository'),
  StorageUseCase: Symbol.for('StorageUseCase'),
};

export const LEADER_ELECTION_TYPES = {
  LeaderElectionRepository: Symbol.for('LeaderElectionRepository'),
  LeaderElectionUseCase: Symbol.for('LeaderElectionUseCase'),
};

export const FAIR_USAGE_TYPES = {
  FairUsageUseCase: Symbol.for('FairUsageUseCase2'),
};

export const ONBOARDING_TYPES = {
  OnboardingUseCase: Symbol.for('OnboardingUseCase'),
};

export const COOKIES_TYPES = {
  CookiesManager: Symbol('CookiesManager'),
  AuthIdentityCookieUseCase: Symbol('AuthIdentityCookieUseCase'),
};

export const INTEGRATION_TYPES = {
  IntegrationState: Symbol.for('IntegrationDbState'),
  IntegrationStateCreator: Symbol.for('IntegrationStateCreator'),
  IntegrationExportState: Symbol.for('IntegrationExportState'),
  IntegrationApiService: Symbol.for('IntegrationApiService'),
  IntegrationRepository: Symbol.for('IntegrationRepository'),
  IntegrationUseCases: Symbol.for('IntegrationUseCases'),
};

export const RETENTION_TYPES = {
  RetentionUseCase: Symbol.for('RetentionUseCase'),
  RetentionRepository: Symbol.for('RetentionRepository'),
  RetentionApiService: Symbol.for('RetentionApiService'),
};

export const ONLINE_TRACKER_TYPES = {
  OnlineTrackerUseCase: Symbol.for('OnlineTrackerUseCase'),
  OnlineTrackerRepository: Symbol.for('OnlineTrackerRepository'),
};

export const EXTENSION_AUTH_TYPES = {
  ExtensionAuthUseCase: Symbol('ExtensionAuthUseCase'),
};

export const RESET_PASSWORD_TYPES = {
  ResetPasswordUseCases: Symbol.for('ResetPasswordUseCases'),
};

export const LOGIN_TYPES = {
  SignInUseCase: Symbol.for('SignInUseCase'),
};

export const GET_FREE_CREDITS_TYPES = {
  GetFreeCreditsUseCase: Symbol.for('GetFreeCreditsUseCase'),
  GetFreeCreditsRepository: Symbol.for('GetFreeCreditsRepository'),
};

export const GEO_SYNC_TYPES = {
  GeoSyncRepository: Symbol.for('GeoSyncRepository'),
  GeoSyncUseCase: Symbol.for('GeoSyncUseCase'),
};

export const NETWORK_TYPES = {
  HttpClient: Symbol.for('HttpClient'),
};

export const SERVER_TIME_TYPES = {
  ServerTimeRepository: Symbol.for('ServerTimeRepository'),
  ServerTimeUseCase: Symbol.for('ServerTimeUseCase'),
};

export const INTEGRATIONS_TYPES = {
  IntegrationsState: Symbol.for('IntegrationsState'),
  IntegrationsApiService: Symbol.for('IntegrationsApiService'),
  IntegrationsRepository: Symbol.for('IntegrationsRepository'),
  IntegrationsUseCases: Symbol.for('IntegrationsUseCases'),
};

export const SIGN_UP_TYPES = {
  SignUpRepository: Symbol.for('SignUpRepository'),
  SignUpUseCases: Symbol.for('SignUpUseCases'),
};

export const CUSTOMER_MESSAGING_TYPES = {
  CustomerMessagingRepository: Symbol.for('CustomerMessagingRepository'),
  CustomerMessagingUseCase: Symbol.for('CustomerMessagingUseCase'),
};

export const DASHBOARD_CHART_TYPES = {
  ChartApiService: Symbol.for('ChartApiService'),
  ChartDao: Symbol.for('ChartDao'),
  ChartRepository: Symbol.for('ChartRepository'),
  ChartUseCase: Symbol.for('ChartUseCase'),
  WorkspaceStatReplicationService: Symbol.for('WorkspaceStatReplicationService'),
};

export const ACCOUNT_SETTINGS_TYPES = {
  AccountSettingsUseCase: Symbol.for('AccountSettingsUseCase'),
};

export const CONTEXTUAL_ONBOARDING_TYPES = {
  ContextualOnboardingRepository: Symbol.for('ContextualOnboardingRepository'),
  ContextualOnboardingUseCase: Symbol.for('ContextualOnboardingUseCase'),
};

export const TEAM_MEMBER_TYPES = {
  TeamMemberDao: Symbol.for('TeamMemberDao'),
  TeamMemberApiService: Symbol.for('TeamMemberApiService'),
  TeamMemberRepository: Symbol.for('TeamMemberRepository'),
  TeamMemberUseCase: Symbol.for('TeamMemberUseCase'),
};

export const PERMISSIONS_TYPES = {
  PermissionsRepository: Symbol.for('PermissionsRepository'),
  PermissionsUseCase: Symbol.for('PermissionsUseCase'),
};

export const BILLING_SETTINGS_TYPES = {
  BillingSettingsUseCase: Symbol.for('BillingSettingsUseCase'),
};

export const PLANS_TYPES = {
  PlansUseCase: Symbol.for('PlansUseCase'),
};

export const HUBSPOT_TYPES = {
  HubspotApiService: Symbol.for('HubspotApiService'),
  HubspotRepository: Symbol.for('HubspotRepository'),
  HubspotUseCase: Symbol.for('HubspotUseCase'),
};
