import { useEffect } from 'react';

import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useAnalytics } from '@/features/system/analytics';

import { useDocumentMeta } from '@/hooks';

export const useDashboardViewModel = (): void => {
  const { trackViewDashboardPage } = useAnalytics();
  const onboarding = useOnboardingUseCase();

  useDocumentMeta({ title: 'dashboard.title', description: 'dashboard.description' });

  useEffect(trackViewDashboardPage, []);

  useEffect(() => {
    onboarding.completeViewDashboardStep();
  }, [onboarding]);
};
