export const useFormattedSecondsLeft = (secondsLeft: number): string => {
  if (secondsLeft < 60) {
    return secondsLeft.toString();
  }

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;
  const secondsFormatted = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${secondsFormatted}`;
};
