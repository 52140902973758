import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  ContactsTable,
  type IContactEntity,
  UpdateNotification,
} from '@/features/common/contact';

import { NoRowsOverlay, Toolbar } from './components';
import { useDashboardTableViewModel } from './useDashboardTableViewModel';

import styles from './styles.module.scss';

export const DashboardTable: FC = () => {
  const { columns, contacts, queryString, handleSortChange } =
    useDashboardTableViewModel();

  return (
    <>
      <Box className={styles.wrapper}>
        <ContactsTable
          checkboxSelection={false}
          getRowId={(row: IContactEntity): string => row.uuid}
          className={clsx(styles.table, {
            [styles.emptyTable]: !contacts.data?.entities.length,
          })}
          loading={contacts.isLoading}
          slots={{
            toolbar: Toolbar,
            noResultsOverlay: NoRowsOverlay,
            noRowsOverlay: NoRowsOverlay,
          }}
          columns={columns}
          rows={contacts.data?.entities || []}
          onSortModelChange={handleSortChange}
        />
      </Box>
      <UpdateNotification queryString={queryString} />
    </>
  );
};
