import { injectable } from 'inversify';

export interface IGoogleTagManagerRepository {
  trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void;
}

@injectable()
export class GoogleTagManagerRepository implements IGoogleTagManagerRepository {
  public trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void {
    if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: eventType,
        ...eventOptions,
      });
    } else {
      console.warn('[GoogleTagManagerRepository]: GTM dataLayer is not available');
    }
  }
}
