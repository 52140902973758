import { inject, injectable } from 'inversify';

import { ANALYTICS_TYPES } from '@/ioc/types';

import { IAmplitudeRepository, WalkthroughStep } from '../domain';

import { IFacebookPixelRepository } from './FacebookPixelRepository';

@injectable()
export class AnalyticsRepository {
  @inject(ANALYTICS_TYPES.AmplitudeRepository)
  private readonly amplitudeRepository: IAmplitudeRepository;

  @inject(ANALYTICS_TYPES.FacebookPixelRepository)
  private readonly facebookPixelRepository: IFacebookPixelRepository;

  public trackUserProductWalkthrough(step: WalkthroughStep): void {
    this.amplitudeRepository.trackEvent('user_product_walkthrough', {
      step,
    });

    if (step === WalkthroughStep.installedExtension) {
      this.facebookPixelRepository.trackEvent('SubmitApplication');
    }
  }
}
