import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Menu, Popover, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { EmailStatus, EmailType } from '@/features/common/contact';
import { useAnalytics } from '@/features/system/analytics';

import { MenuItem } from '@/components';
import {
  ArrowDownIcon,
  CopyIcon,
  DislikeIcon,
  MailIcon,
  MoreHorizIcon,
} from '@/components/Icons';

import { useClipboardWithToast } from '@/hooks';

import { useContactWrongInfoReport } from '../../hooks';

import styles from './styles.module.scss';

type EmailItem = {
  email: string;
  status: EmailStatus;
  type: EmailType;
};

type EmailMenuProps = {
  emails: EmailItem[];
  contactId: string;
};

const EmailMenuActions: FC<{
  item: EmailItem;
  open: boolean;
  anchorEl: HTMLElement | undefined;
  contactId: string;
  onClose: () => void;
}> = ({ item, open, anchorEl, contactId, onClose }) => {
  const { trackContactInfoEmailAction } = useAnalytics();
  const { sendReport } = useContactWrongInfoReport();
  const { t } = useTranslation('contactsTable');

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      classes={{
        list: styles.subMenu,
      }}
    >
      <MenuItem
        className={styles.subMenuItem}
        onClick={(): void => {
          sendReport({ contactId, value: item.email, entityType: 'email' }, () => {
            trackContactInfoEmailAction('wrong info');
          });
          onClose();
        }}
      >
        <DislikeIcon className={styles.subMenuItemIcon} />
        <Box component="span">{t('contactsInfoCell.emailMenu.wrongInfo')}</Box>
      </MenuItem>
    </Menu>
  );
};

export const EmailMenu: FC<EmailMenuProps> = ({ emails, contactId }) => {
  const { t } = useTranslation('contactsTable', {
    keyPrefix: 'contactsInfoCell',
  });
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement>();
  const [activeItem, setActiveItem] = useState<Nullable<EmailItem>>(null);
  const { trackContactInfoEmailAction } = useAnalytics();
  const copyToClipboard = useClipboardWithToast();

  const handleClick = (e): void => {
    setAnchorElement(e.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorElement(undefined);
  };
  const handleCopy = (item: EmailItem): void => {
    void copyToClipboard(item.email).then(handleClose);
  };
  const handleSendEmail = (item: EmailItem): void => {
    window.open(`mailto:${item.email}`);
  };
  const handleMoreClick = (e, item: EmailItem): void => {
    setMenuAnchorElement(e.currentTarget);
    setActiveItem(item);
  };

  return (
    <>
      <Box className={styles.button} onClick={handleClick}>
        <Box className={clsx(styles.buttonBaseIcon, styles.mailIcon)}>
          <MailIcon />
        </Box>
        <Box className={styles.buttonArrowIcon}>
          <ArrowDownIcon />
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box component="ul" className={styles.menuList}>
          {emails.map((item) => {
            return (
              <Box key={item.email} component="li" className={styles.menuListItem}>
                <Box className={styles.menuListTitle}>
                  <Box component="span" className={styles.menuListItemText}>
                    {item.email}
                  </Box>
                  <Box className={styles.menuListItemInfo}>
                    {item.type && (
                      <Box component="span" className={styles.menuListItemType}>
                        {t(`emailMenu.emailTypes.${item.type}`)}
                      </Box>
                    )}
                    {item.status === EmailStatus.Valid && (
                      <Box
                        component="span"
                        className={styles.menuListItemVerificationText}
                      >
                        {t('emailVerified')}
                      </Box>
                    )}
                  </Box>
                </Box>

                <Tooltip title={t('emailMenu.copyTooltip')} placement="top">
                  <IconButton
                    className={styles.menuListItemButton}
                    onClick={(): void => {
                      trackContactInfoEmailAction('copy email');
                      handleCopy(item);
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('emailMenu.sendEmailTooltip')} placement="top">
                  <IconButton
                    onClick={(): void => {
                      trackContactInfoEmailAction('send email');
                      handleSendEmail(item);
                    }}
                    className={styles.menuListItemButton}
                  >
                    <MailIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={(e): void => {
                    handleMoreClick(e, item);
                  }}
                  className={styles.menuListItemButton}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
        {activeItem && (
          <EmailMenuActions
            item={activeItem}
            anchorEl={menuAnchorElement}
            open={Boolean(menuAnchorElement)}
            contactId={contactId}
            onClose={(): void => {
              setMenuAnchorElement(undefined);
              setActiveItem(null);
            }}
          />
        )}
      </Popover>
    </>
  );
};
