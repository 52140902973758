import { useObservableResult } from '@/utils/rx';

import { useExtensionUseCase } from './useExtensionUseCase';

export const useExtensionState = (): {
  isInstalled: boolean;
} => {
  const extensionUseCase = useExtensionUseCase();
  const { data: isInstalled } = useObservableResult(
    () => extensionUseCase.isExtensionInstalled(),
    {
      defaultData: false,
    },
  );

  return {
    isInstalled,
  };
};
