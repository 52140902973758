import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, map, Observable } from 'rxjs';

import { PERMISSIONS_TYPES, TEAM_MEMBER_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { UserRole } from '@/features/common/account';
import { type IPermissionsRepository, Permission } from '@/features/common/permissions';
import {
  type IWorkspaceEntity,
  type IWorkspaceRepository,
  WorkspaceNotFoundError,
  WorkspaceSeatsLimitError,
} from '@/features/common/workspace';
import {
  InvitationStatus,
  ITeamMemberEntity,
  ITeamMemberStateRepository,
} from '@/features/settings';

import type {
  ITeamMemberUseCase,
  ValidationAccessabilityWarning,
} from './abstractions/ITeamMemberUseCase';
import { TeamMemberInviteFreePlanError } from './errors/TeamMemberInviteFreePlanError';
import { TeamMemberInviteGiftPlanLimitationError } from './errors/TeamMemberInviteGiftPlanLimitationError';
import { TeamMemberInvitePermissionDeniedError } from './errors/TeamMemberInvitePermissionDeniedError';
import { TeamMemberNotFoundError } from './errors/TeamMemberNotFoundError';

type SeatsInfo = {
  assumeCount: number;
  paidLimit: number;
  paidCount: number;
  reservedCount: number;
};

type ActionToSeatValidationResult = {
  accessable: boolean;
  limit: number;
  warning?: ValidationAccessabilityWarning;
};

@injectable()
export default class TeamMemberUseCase implements ITeamMemberUseCase {
  @inject(TEAM_MEMBER_TYPES.TeamMemberRepository)
  private repository: ITeamMemberStateRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private permissionsRepository: IPermissionsRepository;

  private getCurrentWorkspace(): Observable<IWorkspaceEntity> {
    return this.workspaceRepository
      .getCurrentWorkspace()
      .pipe(filter((workspace): workspace is IWorkspaceEntity => !!workspace));
  }

  private getSeatsInfo(
    workspace: IWorkspaceEntity,
    payload: {
      email: string;
      role: string;
    }[],
  ): SeatsInfo {
    const assumeCount =
      workspace.billableMembersCount +
      new Set(payload.filter(({ role }) => role !== UserRole.Manager)).size;
    const reservedCount = workspace.members.filter(
      (m) =>
        m.invitationStatus === InvitationStatus.Pending && m.role !== UserRole.Manager,
    ).length;
    const { paidMembersCount, planPaidMembersLimit } = workspace.subscription;

    return {
      assumeCount,
      paidLimit: planPaidMembersLimit || 0,
      paidCount: paidMembersCount,
      reservedCount,
    };
  }

  private validateActionToSeatGiftPlan(
    seatsInfo: SeatsInfo,
  ): ActionToSeatValidationResult {
    const seatsAsumeAndReservedCount = seatsInfo.assumeCount + seatsInfo.reservedCount;

    if (seatsInfo.paidLimit && seatsAsumeAndReservedCount > seatsInfo.paidLimit) {
      throw new TeamMemberInviteGiftPlanLimitationError(undefined, {
        limit: seatsInfo.paidLimit,
      });
    }

    if (seatsAsumeAndReservedCount > seatsInfo.paidCount) {
      throw new TeamMemberInviteGiftPlanLimitationError(undefined, {
        limit: seatsInfo.paidCount,
      });
    }

    return {
      accessable: true,
      limit: seatsInfo.paidLimit || seatsInfo.paidCount,
    };
  }

  private validateActionToSeatDefaultPlan(
    seatsInfo: SeatsInfo,
  ): ActionToSeatValidationResult {
    if (seatsInfo.paidLimit && seatsInfo.assumeCount > seatsInfo.paidLimit) {
      throw new WorkspaceSeatsLimitError('Workspace seats limit reached', {
        limit: seatsInfo.paidLimit,
      });
    }

    if (seatsInfo.assumeCount > seatsInfo.paidCount) {
      return {
        accessable: true,
        limit: seatsInfo.paidCount,
        warning: 'ActionChargeable',
      };
    }

    return {
      accessable: true,
      limit: seatsInfo.paidLimit || seatsInfo.paidCount,
    };
  }

  private validateActionToSeat(
    workspace: IWorkspaceEntity,
    payload: {
      email: string;
      role: string;
    }[],
  ): ActionToSeatValidationResult {
    const seatsInfo = this.getSeatsInfo(workspace, payload);

    if (workspace.subscription.isGift) {
      return this.validateActionToSeatGiftPlan(seatsInfo);
    } else {
      return this.validateActionToSeatDefaultPlan(seatsInfo);
    }
  }

  public async validateUpdateRoleAccessability(payload: {
    email: string;
    role: string;
  }): Promise<{
    accessable: boolean;
    warning?: ValidationAccessabilityWarning;
  }> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );
    if (!workspace) {
      throw new WorkspaceNotFoundError();
    }

    const currentMember = workspace.members.find(
      (member) => member.email === payload.email,
    );

    if (!currentMember) {
      throw new TeamMemberNotFoundError();
    }

    if (
      currentMember.role === UserRole.Manager ||
      currentMember.invitationStatus !== InvitationStatus.Accepted
    ) {
      const validationResult = this.validateActionToSeat(workspace, [payload]);
      return {
        accessable: validationResult.accessable,
        warning: validationResult.warning,
      };
    }

    return {
      accessable: true,
    };
  }

  public validateInvitesAccessability(
    payload: {
      email: string;
      role: string;
    }[],
  ): Observable<{
    accessable: boolean;
    limit: number;
    warning?: ValidationAccessabilityWarning;
  }> {
    return this.getCurrentWorkspace().pipe(
      map((workspace) => {
        return this.validateActionToSeat(workspace, payload);
      }),
    );
  }

  public async iniviteAccessGuard(): Promise<boolean> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );
    const hasPermissionToManageMembers = await firstValueFrom(
      this.permissionsRepository.hasPermissions(Permission.CanInviteTeamMembers),
    );

    if (!workspace) {
      throw new WorkspaceNotFoundError();
    }

    if (workspace.subscription.planIsFree) {
      throw new TeamMemberInviteFreePlanError();
    }

    if (!hasPermissionToManageMembers) {
      throw new TeamMemberInvitePermissionDeniedError();
    }

    return true;
  }

  public async inviteTeamMembers(
    payload: {
      email: string;
      role: string;
    }[],
  ): Promise<IWorkspaceEntity> {
    return this.repository.inviteTeamMembers(payload);
  }

  public deleteTeamMembers(
    payload: {
      email: string;
      reassignTo: string;
    }[],
  ): Promise<boolean> {
    return this.repository.deleteTeamMembers(payload);
  }

  public resendInvitation(payload: {
    email: string;
    role: string;
  }): Promise<IWorkspaceEntity> {
    return this.repository.resendInvitation(payload);
  }

  public getTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace?.members ?? [];
      }),
    );
  }

  public getAcceptedOnlyTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.getTeamMembers().pipe(
      map((members) => {
        return members.filter(
          (member) => member.invitationStatus === InvitationStatus.Accepted,
        );
      }),
    );
  }

  public async updateTeamMemberRole(
    payload: Pick<ITeamMemberEntity, 'email' | 'role'>,
  ): Promise<IWorkspaceEntity> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );

    if (!workspace) {
      throw new WorkspaceNotFoundError();
    }

    const memberToUpdate = workspace.members.find(
      (member) => member.email === payload.email,
    );

    if (!memberToUpdate) {
      throw new TeamMemberNotFoundError();
    }

    return this.workspaceRepository.updateWorkspace({
      ...workspace,
      members: workspace.members.map((member) => {
        if (member.email === payload.email) {
          member.role = payload.role;
        }
        return member;
      }),
    });
  }

  public async setMemberAccessAllContacts(value: boolean): Promise<void> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );

    if (!workspace) {
      throw new WorkspaceNotFoundError();
    }

    this.workspaceRepository.updateWorkspace({
      ...workspace,
      memberAccessAllContacts: value,
    });
  }

  public getMemberAccessAllContacts(): Observable<boolean> {
    return this.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace.memberAccessAllContacts;
      }),
    );
  }

  public getMemberById(uuid: string): Observable<Nullable<ITeamMemberEntity>> {
    return this.getTeamMembers().pipe(
      map((members) => {
        return members.find((member) => member.uuid === uuid);
      }),
    );
  }
}
