import { inject, injectable } from 'inversify';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';

import { BILLING_TYPES, PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import type { IBillingRepository } from '@/features/common/billing';

import type { IReceiptAdjustmentEntity, IUpcomingReceiptEntity } from '../domain';
import { PromotionCurrencyMissmatch } from '../domain/errors/PromotionCurrencyMissmatchError';
import { PromotionExpiredError } from '../domain/errors/PromotionExpiredError';
import { PromotionNotFoundError } from '../domain/errors/PromotionNotFoundError';

import { IPaymentDetailsApiService, IPaymentDetailsRepository } from './abstractions';
import { mapPromotionCodeToAdjustment, mapUpcomingReceipt } from './mappers';

@injectable()
export class PaymentDetailsRepository implements IPaymentDetailsRepository {
  @inject(PAYMENT_DETAILS_TYPES.PaymentDetailsApiService)
  private api: IPaymentDetailsApiService;

  @inject(BILLING_TYPES.BillingRepository)
  private billingRepository: IBillingRepository;

  getPromocode(params: {
    code: string;
    plan: string;
    isApplyable: boolean;
    currency: string;
  }): Observable<IReceiptAdjustmentEntity> {
    return this.api.getPromotionCode(params).pipe(
      catchError(() => throwError(() => new PromotionNotFoundError())),
      switchMap((promoCode) => {
        if (promoCode.expires_at) {
          const expiresAt = new Date(promoCode.expires_at * 1000);

          const now = new Date();
          if (expiresAt.getTime() < now.getTime()) {
            return throwError(() => new PromotionExpiredError());
          }
        }

        if (promoCode.active === false) {
          return throwError(() => new PromotionExpiredError());
        }

        if (promoCode.coupon.currency && promoCode.coupon.currency !== params.currency) {
          return throwError(() => new PromotionCurrencyMissmatch());
        }

        return of(promoCode);
      }),
      map((dc) => mapPromotionCodeToAdjustment(dc)),
      map((entity) => {
        entity.isApplyable = params.isApplyable;
        return entity;
      }),
    );
  }

  getUpcomingReceipt(params: {
    plan: string;
    quantity: number;
    promoCode?: string;
  }): Observable<IUpcomingReceiptEntity> {
    return this.billingRepository
      .getUpcomingInvoice({
        plan: params.plan,
        quantity: params.quantity,
        promoCode: params.promoCode,
      })
      .pipe(map((dc) => mapUpcomingReceipt(dc, { promoCode: params.promoCode })));
  }
}
