import { useEffect, useState } from 'react';

function getSecondsLeft(lockedUntil: Nullable<Date>): number {
  if (!lockedUntil) return 0;
  const nowTime = new Date().getTime();
  const lockedUntilTime = lockedUntil.getTime();

  if (lockedUntilTime <= nowTime) return 0;

  return Math.floor((lockedUntilTime - nowTime) / 1000);
}

export const useSecondsLeft = ({
  lockedUntil,
}: {
  lockedUntil: Nullable<Date>;
}): number => {
  const [secondsLeft, setSecondsLeft] = useState<number>(() =>
    getSecondsLeft(lockedUntil),
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    const checkTimer = (): void => {
      const secondsLeft = getSecondsLeft(lockedUntil);

      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft);
      } else {
        setSecondsLeft(0);
        if (interval) clearTimeout(interval);
      }
    };

    checkTimer();

    interval = setInterval(checkTimer, 1000);

    return () => clearInterval(interval);
  }, [lockedUntil]);

  return secondsLeft;
};
