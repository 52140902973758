import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  EnrichmentUploadCreditsLimitError,
  EnrichmentUploadFileFormatError,
  EnrichmentUploadFileRecordsError,
  EnrichmentUploadFileSizeError,
} from '@/features/enrichment/domain/errors';
import { EnrichmentFileUploadResponse } from '@/features/enrichment/domain/types';
import { useAnalytics } from '@/features/system/analytics';
import { EnrichmentUploadError } from '@/features/system/analytics/domain/types/eventProperties';
import { ConnectionError } from '@/features/system/network';

import { useModalController } from '@/hooks';

import { useEnrichmentFileUploadUseCase } from './hooks';

export type ModalViewType =
  | 'StartViewDefault'
  | 'StartViewConnectionError'
  | 'StartViewSizeError'
  | 'StartViewContentError'
  | 'StartViewFormatError'
  | 'StartViewDefaultError'
  | 'StartViewCreditsLimitError'
  | 'ConfigFieldsMapperView'
  | 'ConfigSettingsView'
  | 'ConfigResultView';

export type FileMetadata = {
  name: string;
  rowsCount: number;
  progress: number;
};

interface IEnrichmentUploadingContext {
  currentView: ModalViewType;
  fileMetadata: Nullable<FileMetadata>;
  fileUploadResponse: Nullable<EnrichmentFileUploadResponse>;
  uploadinModal: ReturnType<typeof useModalController<'UploadingModal'>>;
  uploadAccessDeniedModal: ReturnType<typeof useModalController>;
  toView(viewType: ModalViewType): void;
  upload(file: File): Promise<void>;
}

export const EnrichmentUploadingContext = createContext<IEnrichmentUploadingContext>({
  currentView: 'StartViewDefault',
  fileUploadResponse: null,
  fileMetadata: null,
  uploadinModal: {
    isUploadingModalOpen: false,
    onUploadingModalOpen: () => {
      return;
    },
    onUploadingModalClose: () => {
      return;
    },
  },
  uploadAccessDeniedModal: {
    isOpen: false,
    onOpen: () => {
      return;
    },
    onClose: () => {
      return;
    },
  },
  toView(_viewType: ModalViewType) {
    return;
  },
  upload(_file: File) {
    return Promise.resolve();
  },
});

export const EnrichmentUploadingContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const enrichmentFileUploadUseCase = useEnrichmentFileUploadUseCase();
  const [fileMetadata, setFileMetadata] = useState<Nullable<FileMetadata>>(null);
  const uploadinModal = useModalController('UploadingModal');
  const uploadAccessDeniedModal = useModalController();
  const [currentView, setCurrentView] = useState<ModalViewType>('StartViewDefault');
  const [abortController, setAbortController] = useState(new AbortController());
  const [fileUploadResponse, setFileUploadResponse] =
    useState<Nullable<EnrichmentFileUploadResponse>>(null);
  const { trackEnrichmentUploadError, trackEnrichmentUploadCompleted } = useAnalytics();

  const upload = useCallback(
    async (file: File): Promise<void> => {
      try {
        setFileMetadata({ name: file.name, rowsCount: 0, progress: 0 });
        const response = await enrichmentFileUploadUseCase.uploadFile(
          { file },
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / Number(progressEvent?.total || 1)) * 100,
              );
              setFileMetadata((prev) => {
                if (prev) return { ...prev, progress };
                return prev;
              });
            },
            signal: abortController.signal,
          },
        );

        trackEnrichmentUploadCompleted();

        setFileMetadata({ rowsCount: response.total, name: file.name, progress: 0 });
        setCurrentView('ConfigFieldsMapperView');
        setFileUploadResponse(response);
      } catch (error) {
        switch (true) {
          case error instanceof ConnectionError:
            trackEnrichmentUploadError(EnrichmentUploadError.InternetConnection);
            setCurrentView('StartViewConnectionError');
            break;
          case error instanceof EnrichmentUploadFileSizeError:
            trackEnrichmentUploadError(EnrichmentUploadError.FileSizeOverLimit);
            setCurrentView('StartViewSizeError');
            break;
          case error instanceof EnrichmentUploadFileRecordsError:
            trackEnrichmentUploadError(EnrichmentUploadError.RecordsOverLimit);
            setCurrentView('StartViewContentError');
            break;
          case error instanceof EnrichmentUploadFileFormatError:
            trackEnrichmentUploadError(EnrichmentUploadError.FileFormatInvalid);
            setCurrentView('StartViewFormatError');
            break;
          case error instanceof EnrichmentUploadCreditsLimitError:
            setFileMetadata((prev) => {
              if (prev) {
                return { ...prev, rowsCount: error.options.fileMetadata.records };
              }
              return prev;
            });
            trackEnrichmentUploadError(EnrichmentUploadError.NotEnoughCredits);
            setCurrentView('StartViewCreditsLimitError');
            break;
          default:
            setCurrentView('StartViewDefaultError');
            break;
        }
      } finally {
        setAbortController(new AbortController());
      }
    },
    [abortController.signal],
  );

  const contextValue = useMemo(() => {
    return {
      currentView,
      fileMetadata,
      fileUploadResponse,
      uploadinModal: {
        ...uploadinModal,
        onUploadingModalClose: (): void => {
          if (currentView === 'StartViewDefault') {
            abortController.abort();
          }
          uploadinModal.onUploadingModalClose();
        },
      },
      uploadAccessDeniedModal,
      toView: setCurrentView,
      upload,
    };
  }, [
    currentView,
    fileMetadata,
    fileUploadResponse,
    uploadinModal,
    uploadAccessDeniedModal,
    abortController,
  ]);

  return (
    <EnrichmentUploadingContext.Provider value={contextValue}>
      {children}
    </EnrichmentUploadingContext.Provider>
  );
};

export function withEnrichmentUploadingContextProvider<Props extends object>(
  Component: FC<Props>,
): FC<Props> {
  return (props: Props) => {
    return (
      <EnrichmentUploadingContextProvider>
        {/* @ts-ignore */}
        <Component {...props} />
      </EnrichmentUploadingContextProvider>
    );
  };
}
