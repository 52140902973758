import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { map, of } from 'rxjs';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import type { IProductEntity } from '@/features/common/billing';
import {
  useSubscriptionUseCase,
  useWorkspaceSubscription,
} from '@/features/common/workspace';
import { useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { openInNewTab } from '@/utils/openInNewTab';
import { useObservableResult } from '@/utils/rx';

import { useChangeBillingCycleState } from './hooks';

export type BillingCycleDialogViewModel = {
  isOpened: boolean;
  isProcessing: boolean;
  isDowngrade: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onOpen: (params: { product: IProductEntity; seats: number }) => void;
  text: string;
  changeDate: string;
};

export function useBillingCycleDialogViewModel(): BillingCycleDialogViewModel {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');
  const subscriptionUseCase = useSubscriptionUseCase();

  const navigate = useNavigate();

  const { trackChangeSubscription } = useAnalytics();

  const snackbar = useSnackbar();

  const [state, handlers] = useChangeBillingCycleState();

  const nextProduct = state.isOpened ? state.product : null;

  const { data: isDowngrade } = useObservableResult(
    () =>
      nextProduct
        ? subscriptionUseCase.isUpgrade(nextProduct).pipe(map((isUpgrade) => !isUpgrade))
        : of(false),
    {
      deps: [nextProduct],
      defaultData: false,
    },
  );

  const logger = useAppLogger();

  const { data: subscription } = useWorkspaceSubscription();

  const changeDate = subscription?.expirationDate
    ? dayjs(dayjs.unix(subscription?.expirationDate), {
        locale: language,
      }).format('MMMM D, YYYY')
    : '';

  const planName = nextProduct?.name ?? '';

  const billingCycle = nextProduct ? t(`billingCycleDialog.${nextProduct.cycle}`) : '';

  const text = `${planName} (${billingCycle})`;

  const onUpgradeConfirm = async (): Promise<void> => {
    try {
      if (!state.isOpened) {
        return;
      }

      handlers.startProcessing();

      await subscriptionUseCase.update({
        plan: state.product.id,
        quantity: state.seats,
      });

      snackbar.enqueueSnackbar(t('billingCycleDialog.success'), {
        variant: 'success',
        description: t('billingCycleDialog.successDescription'),
        autoHideDuration: 5000,
      });

      handlers.processedWithSuccess();

      trackChangeSubscription(`Change to ${state.product.cycle}`);

      navigate(ROUTES.SETTINGS.SUBSCRIPTION);
    } catch (e) {
      logger.error(e);

      snackbar.enqueueSnackbar(t('billingCycleDialog.error'), {
        variant: 'error',
        description: t('billingCycleDialog.errorDescription'),
        autoHideDuration: 5000,
      });
      handlers.processedWithError();
    }
  };

  const onDowngradeConfirm = (): void => {
    openInNewTab(EXTERNAL_ROUTES.TALK_TO_SALES);
  };

  const handleConfirm = async (): Promise<void> => {
    if (isDowngrade) {
      onDowngradeConfirm();
    } else {
      await onUpgradeConfirm();
    }
  };

  return {
    isOpened: state.isOpened,
    isProcessing: state.isOpened && state.isPorcessing,
    isDowngrade,
    onClose: (): void => {
      handlers.close();
    },
    onConfirm: handleConfirm,
    onOpen: (params: { product: IProductEntity; seats: number }): void => {
      handlers.open(params);
    },
    text,
    changeDate,
  };
}
