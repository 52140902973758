import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useSubscriptionUseCase } from '@/features/common/workspace';
import { useAnalytics } from '@/features/system/analytics';

type CancelSubscriptionViewModel = {
  isProcessing: boolean;
  submit: () => Promise<void>;
};

export const useCancelSubscriptionViewModel = (params: {
  onClose: () => void;
}): CancelSubscriptionViewModel => {
  const { t } = useTranslation('settings', { keyPrefix: 'subscriptions' });

  const { trackCancelSubscription } = useAnalytics();
  const subscriptionUseCase = useSubscriptionUseCase();
  const snackbar = useSnackbar();

  const [isProcessing, setIsProcessing] = useState(false);

  return {
    isProcessing: isProcessing,
    submit: async (): Promise<void> => {
      try {
        setIsProcessing(true);
        await subscriptionUseCase.cancel();
        params.onClose();
        snackbar.enqueueSnackbar(t('subscriptionCancelled.title'), {
          variant: 'success',
          description: t('subscriptionCancelled.description'),
        });
        trackCancelSubscription();
      } catch {
        snackbar.enqueueSnackbar(t('subscriptionCancelFailed.title'), {
          variant: 'error',
          description: t('subscriptionCancelFailed.description'),
        });
      } finally {
        setIsProcessing(false);
      }
    },
  };
};
