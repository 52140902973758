import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const InviteWarningTip: FC<{
  limit: number;
  type: 'ActionChargeable';
}> = ({ limit, type }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'teamManagement.inviteDialog',
  });
  const getI18nKey = (): string => {
    switch (type) {
      case 'ActionChargeable':
        return 'inviteCharcheableWarningTip.text';
    }
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.icon}>
        <InfoOutlinedIcon />
      </Box>
      <Typography className={styles.text}>
        <Trans
          t={t}
          i18nKey={getI18nKey()}
          values={{ count: limit }}
          components={{
            b: <b />,
          }}
        />
      </Typography>
    </Box>
  );
};
