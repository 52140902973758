import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const ResendCode: FC<{
  onResendCode: () => void;
  disabled?: boolean;
}> = ({ onResendCode, disabled }) => {
  const { t } = useTranslation('auth', { keyPrefix: 'phoneVerification' });

  return (
    <Typography variant="subtitle1" className={styles.resendCodeButton}>
      {t('resend.title')}{' '}
      <Button onClick={onResendCode} disabled={disabled}>
        {t('resend.button')}
      </Button>
    </Typography>
  );
};
