import { DomainError } from '@/features/common/error';

export class TeamMemberInviteGiftPlanLimitationError extends DomainError {
  metadata?: {
    limit: number;
  };

  constructor(message?: string, metadata?: { limit: number }) {
    super(message || 'Team member invite gift plan limitation error');
    this.name = 'TeamMemberInviteGiftPlanLimitationError';
    this.metadata = metadata;
  }
}
