import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

const ApiUrl = {
  resetPassword: '/api/v1/account/email',
  getInfoByIp: '/api/v1/account/ip-info',
  exchangeAuthCode: '/api/v1/account',
  sendPhoneVerificationCode: '/api/v1/account/send-verification-code',
  verifySmsCode: '/api/v1/account/verify-sms-code',
  validatePhone: '/api/v1/account/validate-phone',
};

export interface IAuthApiService {
  sendResetPasswordLink(email: string): Observable<boolean>;
  sendEmailVerificationLink(email: string): Observable<boolean>;
  sendPhoneVerificationCode(): Observable<{
    sent: boolean;
    next_attempt_in: number | null;
  }>;
  verifySmsCode(code: string): Observable<boolean>;
  validatePhone(phone: string): Observable<boolean>;
  getUserInfoByIp(): Observable<{ country_code: string }>;
}

@injectable()
export class AuthApiService implements IAuthApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  public sendResetPasswordLink(email: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(ApiUrl.resetPassword, {
        email,
        email_type: 'reset_password',
      })
      .pipe(map((response) => response.data));
  }

  public getUserInfoByIp(): Observable<{ country_code: string }> {
    return this.httpClient.get<{ country_code: string }>(ApiUrl.getInfoByIp).pipe(
      map(({ data }) => {
        return data;
      }),
    );
  }

  public sendEmailVerificationLink(email: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(ApiUrl.resetPassword, {
        email,
        email_type: 'confirm_email',
      })
      .pipe(map((response) => response.data));
  }

  public sendPhoneVerificationCode(): Observable<{
    sent: boolean;
    next_attempt_in: number | null;
  }> {
    return this.httpClient
      .post<{
        sent: boolean;
        next_attempt_in: number | null;
      }>(ApiUrl.sendPhoneVerificationCode, undefined)
      .pipe(map((response) => response.data));
  }

  public validatePhone(phone: string): Observable<boolean> {
    return this.httpClient
      .get<string | boolean>(`${ApiUrl.validatePhone}?phone=${phone}`)
      .pipe(map((response) => response.data === 'true' || response.data === true));
  }

  public verifySmsCode(code: string): Observable<boolean> {
    return this.httpClient
      .get<boolean>(ApiUrl.verifySmsCode, { params: { code } })
      .pipe(map((response) => response.data));
  }
}
