import { useState } from 'react';

import { useAnalytics } from '@/features/system/analytics';

import { DATE_SELECT_OPTION_VALUES } from '@/components';

type Return = {
  handleDateChange(value: string): void;
  selectedDate: DATE_SELECT_OPTION_VALUES;
};

export const useDateSelectFacade = (): Return => {
  const { trackFilterByDate } = useAnalytics();

  const [selectedDate, setSelectedDate] = useState<DATE_SELECT_OPTION_VALUES>(
    DATE_SELECT_OPTION_VALUES.lastMonth,
  );

  const handleDateChange = (value: DATE_SELECT_OPTION_VALUES): void => {
    setSelectedDate(value);
    trackFilterByDate('dashboard', value);
  };

  return {
    selectedDate,
    handleDateChange,
  };
};
