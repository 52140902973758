import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tooltip } from '@mui/material';
import { Typography } from '@mui/material';

import {
  CreateNewListPopover,
  useCreateNewListPopover,
} from '@/features/common/contactList';
import { ActionDisableGuard } from '@/features/common/permissions';
import { useAnalytics } from '@/features/system/analytics';

import { Preview } from '@/components';

import { CommonActionProps } from '../types';

import styles from './styles.module.scss';

type NewListActionProps = CommonActionProps;

export const NewListAction: FC<NewListActionProps> = ({ isLoading = false }) => {
  const { anchorEl, setAnchorEl, handleClose } = useCreateNewListPopover();
  const { t } = useTranslation('lists');
  const { trackNewListClick } = useAnalytics();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);
    trackNewListClick();
  };
  const isOpen = Boolean(anchorEl);

  if (isLoading) {
    return (
      <Box className={styles.newList}>
        <Preview />
      </Box>
    );
  }

  return (
    <Box className={styles.newList}>
      <Tooltip
        title={t('subhead.newList.tooltip')}
        placement="bottom-end"
        classes={{ tooltip: styles.newListTooltip }}
      >
        <ActionDisableGuard>
          <Button variant="contained" onClick={handleClick}>
            <Typography variant="textInButton">{t('subhead.newList.button')}</Typography>
          </Button>
        </ActionDisableGuard>
      </Tooltip>
      <CreateNewListPopover anchorEl={anchorEl} isOpen={isOpen} onClose={handleClose} />
    </Box>
  );
};
