import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { useFormattedSecondsLeft } from '../../hooks';

export const ResendTimer: FC<{ secondsLeft: Nullable<number> }> = ({ secondsLeft }) => {
  const { t } = useTranslation('auth', { keyPrefix: 'phoneVerification' });

  if (!secondsLeft) {
    return null;
  }

  const formattedSecondsLeft = useFormattedSecondsLeft(secondsLeft);

  return (
    <Typography variant="subtitle1" lineHeight="15px">
      {t('resendTimer')} <Box component="b">{formattedSecondsLeft}</Box>
    </Typography>
  );
};
