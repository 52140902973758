import { VerificationStatus } from '../../domain/types';
import { IAccountDC } from '../dataContracts/AccountDC';

export const mapVerificationStatus = (dc: IAccountDC): VerificationStatus => {
  const phoneVerificationRequired = dc.settings.phone_verification_required;
  const phoneVerified = dc.settings.phone_verified;

  if (phoneVerificationRequired && phoneVerified) {
    return VerificationStatus.Verified;
  }

  if (phoneVerificationRequired) {
    return VerificationStatus.ShouldVerifyPhone;
  } else {
    if (!dc.unconfirmed_email) {
      return VerificationStatus.Verified;
    }

    if (dc.email === dc.unconfirmed_email) {
      return VerificationStatus.ShouldVerifySignUpEmail;
    }

    if (dc.email !== dc.unconfirmed_email) {
      return VerificationStatus.ShouldVerifyChangeEmail;
    }
  }

  return VerificationStatus.Verified;
};
