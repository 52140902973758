import React, { ReactElement, useEffect, useState } from 'react';

import { useWorkspaceSubscription } from '@/features/common/workspace';
import { useAnalytics, WalkthroughStep } from '@/features/system/analytics';
import { useHelpCenterUseCase } from '@/features/system/helpCenter/ui';

import { useWidgetManager } from '@/hooks';

import { useObservableResult } from '@/utils/rx';

import { OnboardingStep } from '../../../domain/types';
import { useOnboardingUseCase } from '../../hooks/useOnboardingUseCase';

export const useOnboardingWidgetViewModel = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const { trackUserProductWalkthrough } = useAnalytics();
  const onboardingUseCase = useOnboardingUseCase();
  const helpCenterUseCase = useHelpCenterUseCase();
  const onboardingWidget = useWidgetManager('Onboarding');
  const { data: subscription } = useWorkspaceSubscription();

  const { data: stepList } = useObservableResult(() => onboardingUseCase.getStepList(), {
    deps: [],
    defaultData: [],
  });

  const onClickButton = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(e.currentTarget);
    if (onboardingWidget.isOpen) {
      onboardingWidget.close();
    } else {
      onboardingWidget.open({ closeRest: true });
    }
  };

  const onClose = (): void => {
    onboardingWidget.close();
  };

  const onClickInstallExtension = (): void => {
    trackUserProductWalkthrough(WalkthroughStep.idle);
    onboardingUseCase.completeClickDownloadExtensionStep();
    onClose();
  };

  const renderStep = (
    step: OnboardingStep,
    fn: (param: {
      step: OnboardingStep;
      status: 'completed' | 'incompleted';
    }) => ReactElement,
  ): Nullable<ReactElement> => {
    const item = stepList.find((item) => item.step === step);
    if (!item) {
      return null;
    }
    return fn(item);
  };

  const isVisible =
    stepList.some((item) => item.status === 'incompleted') && onboardingWidget.isVisible;

  useEffect(() => {
    const subscription = onboardingUseCase.startListeners().subscribe();
    return (): void => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    helpCenterUseCase.onShow(() => {
      onClose();
    });
  }, []);

  return {
    isOpen: onboardingWidget.isOpen,
    anchorEl,
    onClickButton,
    onClose,
    onClickInstallExtension,
    renderStep,
    isVisible,
    isFreePlan: subscription?.planIsFree ?? false,
  };
};
