import { useEffect } from 'react';

import { useInjection } from '@/ioc/ioc.react';
import { CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import {
  CustomerMessagingEventKeys,
  ICustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

const counterStorageKeyPrefix = 'cm_page_view_counter_';

export const useCMPage = (
  eventKey: CustomerMessagingEventKeys,
  secondViewEventKey?: CustomerMessagingEventKeys,
): void => {
  const cmUseCase = useInjection<ICustomerMessagingUseCase>(
    CUSTOMER_MESSAGING_TYPES.CustomerMessagingUseCase,
  );

  useEffect(() => {
    if (secondViewEventKey) {
      const storageKey = counterStorageKeyPrefix + eventKey;
      const counter = localStorage.getItem(storageKey);

      if (!counter) {
        cmUseCase.page(eventKey);
        localStorage.setItem(storageKey, '1');
      } else if (counter === '1') {
        cmUseCase.page(secondViewEventKey);
      }
    } else {
      cmUseCase.page(eventKey);
    }
  }, []);
};
