import dayjs from 'dayjs';

import { type IAccountDC, type IAccountEntity } from '@/features/common/account';

import { mapVerificationStatus } from './mapVerificationStatus';

export const mapOnboardingEntityKey = (
  onboadingKey: keyof IAccountEntity['settings']['onboarding'],
): keyof IAccountDC['settings']['onboarding'] => {
  switch (onboadingKey) {
    case 'isContactExported':
      return 'is_contact_exported';
    case 'isContactRevealed':
      return 'is_contact_revealed';
    case 'isExtensionInstalled':
      return 'is_extension_installed';
    case 'isTeamMemberInvited':
      return 'is_team_member_invited';
    case 'isSignupComplete':
      return 'is_signup_complete';
    case 'isViewedInstallExtensionPage':
      return 'is_viewed_install_extension_page';
    case 'isViewedDashboard':
      return 'is_viewed_dashboard';
    case 'isClickedDownloadExtension':
      return 'is_clicked_download_extension';
    case 'mobileSignupOpenChromeStore':
      return 'mobile_signup_open_chrome_store';
  }
};

export const mapAccountDcToEntity = (dc: IAccountDC): IAccountEntity => {
  return {
    uuid: dc.uuid,
    fullName: dc.full_name,
    email: dc.email,
    adminEmail: dc.admin_email,
    thumbnail: dc.thumbnail,
    lastActivity: dc.last_activity,
    role: dc.role,
    settings: {
      language: dc.settings.language,
      country: dc.settings.country,
      onboarding: {
        isContactExported: dc.settings.onboarding.is_contact_exported,
        isContactRevealed: dc.settings.onboarding.is_contact_revealed,
        isExtensionInstalled: dc.settings.onboarding.is_extension_installed,
        isTeamMemberInvited: dc.settings.onboarding.is_team_member_invited,
        isSignupComplete: dc.settings.onboarding.is_signup_complete,
        isViewedInstallExtensionPage:
          dc.settings.onboarding.is_viewed_install_extension_page,
        isViewedDashboard: dc.settings.onboarding.is_viewed_dashboard,
        isClickedDownloadExtension: dc.settings.onboarding.is_clicked_download_extension,
        mobileSignupOpenChromeStore:
          dc.settings.onboarding.mobile_signup_open_chrome_store,
      },
      isEmailVerified: dc.settings.is_email_verified,
      phone: dc.settings.phone,
      phoneVerificationCodeSentAt: dc.settings.phone_verification_code_sent_at,
      phoneVerificationRequired: dc.settings.phone_verification_required,
      phoneVerified: dc.settings.phone_verified,
      extensionPosition: dc.settings.extension_position,
      openAutomatically: dc.settings.open_automatically,
      hasTriedToUninstallExtension: dc.settings.has_tried_to_uninstall_extension ?? false,
      gotRetentionCredits: dc.settings.got_retension_credits,
      isImportedToHubspot: dc.settings.is_imported_to_hubspot,
    },
    verificationStatus: mapVerificationStatus(dc),
    isUserSignUpFinished: !!(dc.full_name && dc.settings.phone),
    unconfirmedEmail: dc.unconfirmed_email,
    updatedAt: dayjs.unix(dc.updated_at).toISOString(),
    createdAt: dayjs.unix(dc.created_at).toISOString(),
    providers: dc.providers,
    totalCreditsUsed: dc.total_credits_used,
    totalSearchesCount: dc.total_searches_count,
    totalResultsCount: dc.total_results_count,
    currentResultsCount: dc.current_results_count,
    currentSearchCount: dc.current_search_count,
    currentCreditsUsed: dc.current_credits_used,
    personalWorkspaceId: dc.personal_workspace_id,
    referrerToken: dc.referrer_token,
    referrerCreditsEarned: dc.referrer_credits_earned,
    isInvited: dc.is_invited,
    utm: dc.utm,
  };
};

export const mapAccountEntityToDc = (entity: IAccountEntity): IAccountDC => {
  return {
    uuid: entity.uuid,
    full_name: entity.fullName,
    email: entity.email,
    admin_email: entity.adminEmail,
    thumbnail: entity.thumbnail,
    last_activity: entity.lastActivity,
    role: entity.role,
    providers: entity.providers,
    settings: {
      language: entity.settings?.language,
      country: entity.settings?.country,
      onboarding: {
        is_contact_exported: entity.settings.onboarding.isContactExported,
        is_contact_revealed: entity.settings.onboarding.isContactRevealed,
        is_extension_installed: entity.settings.onboarding.isExtensionInstalled,
        is_team_member_invited: entity.settings.onboarding.isTeamMemberInvited,
        is_signup_complete: entity.settings.onboarding.isSignupComplete,
        is_viewed_dashboard: entity.settings.onboarding.isViewedDashboard,
        is_viewed_install_extension_page:
          entity.settings.onboarding.isViewedInstallExtensionPage,
        is_clicked_download_extension:
          entity.settings.onboarding.isClickedDownloadExtension,
        mobile_signup_open_chrome_store:
          entity.settings.onboarding.mobileSignupOpenChromeStore,
      },
      is_email_verified: entity.settings.isEmailVerified,
      phone: entity.settings.phone,
      phone_verification_code_sent_at: entity.settings.phoneVerificationCodeSentAt,
      phone_verification_required: entity.settings.phoneVerificationRequired,
      phone_verified: entity.settings.phoneVerified,
      extension_position: entity.settings.extensionPosition,
      open_automatically: entity.settings.openAutomatically,
      has_tried_to_uninstall_extension: entity.settings.hasTriedToUninstallExtension,
      got_retension_credits: entity.settings.gotRetentionCredits,
      is_imported_to_hubspot: entity.settings.isImportedToHubspot,
    },
    unconfirmed_email: entity.unconfirmedEmail,
    updated_at: dayjs(entity.updatedAt).unix(),
    created_at: dayjs(entity.createdAt).unix(),
    total_credits_used: entity.totalCreditsUsed,
    total_searches_count: entity.totalSearchesCount,
    total_results_count: entity.totalResultsCount,
    current_credits_used: entity.currentCreditsUsed,
    current_search_count: entity.currentSearchCount,
    current_results_count: entity.currentResultsCount,
    personal_workspace_id: entity.personalWorkspaceId,
    referrer_token: entity.referrerToken,
    referrer_credits_earned: entity.referrerCreditsEarned,
    is_invited: entity.isInvited,
    utm: entity.utm,
  };
};
