import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

type PaymentStatusProps = {
  status: 'active' | 'canceled' | 'suspended' | 'grace';
  isGift: boolean;
  nextPayment?: Nullable<{
    amount: string;
    expiresAt?: string;
  }>;
  expiresAt?: string;
};

const InfoText: FC<PropsWithChildren> = ({ children }) => (
  <Typography component="span" color="info" fontSize="13px">
    {children}
  </Typography>
);

const ErrorText: FC<PropsWithChildren> = ({ children }) => (
  <Typography component="span" color="error" fontSize="13px">
    {children}
  </Typography>
);

export const PaymentStatus: FC<PaymentStatusProps> = ({
  status,
  isGift,
  nextPayment,
  expiresAt,
}) => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'paymentDetails',
  });

  if (status === 'active') {
    if (isGift) {
      return <InfoText>{t('gifted')}</InfoText>;
    }

    if (!nextPayment) {
      return <InfoText>{t('noNextPayment', {})}</InfoText>;
    }

    return (
      <InfoText>
        {t('nextPayment', {
          amount: nextPayment.amount,
          date: nextPayment.expiresAt,
        })}
      </InfoText>
    );
  }

  if (status === 'canceled') {
    return (
      <ErrorText>{t('expired', { date: nextPayment?.expiresAt ?? expiresAt })}</ErrorText>
    );
  }

  if (status === 'suspended') {
    return <ErrorText>{t('accountSuspended')}</ErrorText>;
  }

  if (status === 'grace') {
    return <ErrorText>{t('lastPaymentFailed')}</ErrorText>;
  }

  return null;
};
