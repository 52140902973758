import { tap } from 'rxjs';

import { useObservableResult } from '@/utils/rx';

import { AuthState } from '../../domain';

import { useAuthUseCase } from './useAuthUseCase';

export function useAuthState(): AuthState {
  const authUseCase = useAuthUseCase();

  const { data: authState } = useObservableResult(
    () => authUseCase.getAuthState().pipe(tap((as) => console.log('[AuthState]', as))),
    {
      defaultData: AuthState.Identifying,
    },
  );

  return authState;
}
