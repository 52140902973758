import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useAnalytics } from '@/features/system/analytics';

import { useRetentionUseCase } from './hooks';

type ViewModel = () => {
  onAcceptClick: () => void;
  onRejectClick: () => void;
};

export const useLeavingSoonViewModel: ViewModel = () => {
  const useCase = useRetentionUseCase();
  const onboardingUseCase = useOnboardingUseCase();
  const navigate = useNavigate();
  const {
    trackReceivedRetentionOffer,
    trackAcceptedRetentionOffer,
    trackDeclinedRetentionOffer,
  } = useAnalytics();

  useEffect(() => {
    const subscription = useCase
      .canUserReceiveCredits()
      .subscribe((canUseRecieveCredits) => {
        if (canUseRecieveCredits) {
          trackReceivedRetentionOffer();
        } else {
          navigate(ROUTES.DASHBOARD);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  const onAcceptClick = (): void => {
    useCase.acceptRetentionOffer().subscribe();
    trackAcceptedRetentionOffer();
    window.open(EXTERNAL_ROUTES.EXTENSION_URL);
    navigate(ROUTES.DASHBOARD);
    onboardingUseCase.completeClickDownloadExtensionStep();
  };

  const onRejectClick = (): void => {
    useCase.declineRetentionOffer().subscribe();
    trackDeclinedRetentionOffer();
    navigate(ROUTES.DASHBOARD);
  };

  return {
    onAcceptClick,
    onRejectClick,
  };
};
