import { FC } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Theme } from '@mui/material';

const ColumnSortedAscendingIcon: FC = () => {
  return (
    <Box className="ColumnHeaderSortedIconContainer ColumnHeaderSortedAscendingIconContainer">
      <ExpandLess className="ColumnHeaderSortedIcon-top ColumnHeaderSortedAscendingIcon-top" />
      <ExpandMore className="ColumnHeaderSortedIcon-bottom ColumnHeaderSortedAscendingIcon-bottom" />
    </Box>
  );
};

const ColumnSortedDescendingIcon: FC = () => {
  return (
    <Box className="ColumnHeaderSortedIconContainer ColumnHeaderSortedDescendingIconContainer">
      <ExpandLess className="ColumnHeaderSortedIcon-top ColumnHeaderSortedDescendingIcon-top" />
      <ExpandMore className="ColumnHeaderSortedIcon-bottom ColumnHeaderSortedDescendingIcon-bottom" />
    </Box>
  );
};

export const MuiDataGrid = {
  defaultProps: {
    rowSelection: false,
    slots: {
      columnSortedAscendingIcon: ColumnSortedAscendingIcon,
      columnSortedDescendingIcon: ColumnSortedDescendingIcon,
    },
  },
  variants: [
    {
      props: { variant: 'gapped' },
      style: ({ theme }: { theme: Theme }): Record<string, unknown> => ({
        '--DataGrid-containerBackground': '#f5f5fa',
        '.MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '.MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell': {
          borderTop: `solid 2px ${theme.palette.info.disabled}`,
          borderBottom: `solid 2px ${theme.palette.info.disabled}`,
          '&:first-of-type': {
            borderRadius: '10px 0 0 10px',
            borderLeft: `solid 2px ${theme.palette.info.disabled}`,
          },
        },
        '.MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell:last-child': {
          borderRadius: '0 10px 10px 0',
          borderRight: `solid 2px ${theme.palette.info.disabled}`,
        },
        '.MuiDataGrid-main .MuiDataGrid-row:hover .MuiDataGrid-cell': {
          borderColor: theme.palette.primary.disabled,
        },
        '.MuiDataGrid-footerContainer': {
          border: 'none',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600,
        },
        '.MuiDataGrid-row': {
          backgroundColor: 'white',
          borderRadius: 10,
          ':hover': {
            backgroundColor: 'white',
            boxShadow: `0 10px 20px 0 ${theme.palette.info.disabled}`,
          },
          '&.Mui-hovered': {
            backgroundColor: 'white',
          },
        },
        '.MuiDataGrid-virtualScrollerRenderZone': {
          gap: '10px',
        },
      }),
    },
    {
      props: { variant: 'basic' },
      style: (): Record<string, unknown> => ({
        '--DataGrid-containerBackground': 'white',
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '0 14px',
        '.MuiDataGrid-row': {
          backgroundColor: '#fff !important',
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }: { theme: Theme }): Record<string, unknown> => ({
      border: 'none',
      '--DataGrid-rowBorderColor': theme.palette.info.disabled,
      '.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
        {
          outline: 'none',
        },
      '.MuiDataGrid-cell.MuiDataGrid-cell--editing': {
        background: 'none',
        boxShadow: 'none',
        padding: '0 10px',
      },
      '.MuiDataGrid-cell': {
        outline: 'none !important',
        display: 'flex',
        alignItems: 'center',
      },
      '.MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    }),
  },
};
