import { ChangeEvent, MutableRefObject, useContext, useRef } from 'react';

import { useCurrentPlanType } from '@/features/common/billing';
import { useAnalytics } from '@/features/system/analytics';

import { EnrichmentUploadingContext } from '../EnrichmentUploadingContext';

import { useEnrichmentFileUploadUseCase } from './useEnrichmentFileUploadUseCase';

export const useUploadEnrichmentFile = (): {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onClick(): void;
} => {
  const enrichmentFileUploadUseCase = useEnrichmentFileUploadUseCase();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { uploadinModal, upload, toView, uploadAccessDeniedModal } = useContext(
    EnrichmentUploadingContext,
  );
  const { trackEnrichmentUploadBtnClick } = useAnalytics();
  const { data: planType } = useCurrentPlanType();

  const onChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target.files?.length) return;

    try {
      const newFile = event.target.files[0];
      upload(newFile);
      toView('StartViewDefault');
      uploadinModal.onUploadingModalOpen();
    } catch (error) {
    } finally {
      event.target.value = '';
    }
  };

  const onClick = async (): Promise<void> => {
    try {
      trackEnrichmentUploadBtnClick(planType);
      await enrichmentFileUploadUseCase.checkUploadPermissions();
      inputRef.current?.click();
    } catch (error) {
      uploadAccessDeniedModal.onOpen();
    }
  };

  return {
    inputRef,
    onChange,
    onClick,
  };
};
