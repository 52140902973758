import { useNavigate } from 'react-router';

import { ROUTES } from '@/router/routes';

import { useAnalytics } from '@/features/system/analytics';

import { IIntegrationEntity } from '../../../domain/entities';

export const useIntegrationCardViewModel = (integration: IIntegrationEntity) => {
  const navigate = useNavigate();
  const shouldContactSales = integration.id === 'custom';
  const { trackIntegrationsConnectClick } = useAnalytics();

  const trackAnalyticConnect = (): void => {
    trackIntegrationsConnectClick(integration.name);
  };

  const handleManage = (e): void => {
    if (e.metaKey) return;
    e.preventDefault();
    navigate(ROUTES.INTEGRATION.replace(':id', integration.id));
  };

  return {
    shouldContactSales,
    handleManage,
    trackAnalyticConnect,
  };
};
