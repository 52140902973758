/**
 * As we have a multi-step authentication process, we need to keep track of the current auth state.
 * This enum is used to keep track of the current state.
 *
 * @enum AuthState
 * @property {string} Identifying - The step is not determined yet, as we are still loading the user or account.
 * @property {string} ShouldProvideCredentials - The user should provide their credentials (sign up / sign in).
 * @property {string} ShouldSetupAccount - The user should setup their account (name, phone number, password if needed etc).
 * @property {string} ShouldVerifyEmail - The user should verify their email.
 * @property {string} CanAccessSite - The user can access the site.
 */

export enum AuthState {
  Identifying = 'Identifying',
  ShouldProvideCredentials = 'ShouldProvideCredentials',
  ShouldSetupAccount = 'ShouldSetupAccount',
  ShouldVerifyEmail = 'ShouldVerifyEmail',
  ShouldVerifyPhone = 'ShouldVerifyPhone',
  CanAccessSite = 'CanAccessSite',
}
