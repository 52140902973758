import { ROUTES } from '@/router/routes';

import { AuthState } from '@/features/common/auth';

export const DEFAULT_ROUTE_BY_AUTH_STEP = {
  [AuthState.CanAccessSite]: ROUTES.DASHBOARD,
  [AuthState.ShouldProvideCredentials]: ROUTES.LOGIN,
  [AuthState.ShouldSetupAccount]: ROUTES.SIGN_UP_NEW,
  [AuthState.ShouldVerifyEmail]: ROUTES.SIGN_UP_VERIFICATION,
  [AuthState.ShouldVerifyPhone]: ROUTES.SIGN_UP_VERIFICATION_PHONE,
};

export function getDefaultRoute(authState: AuthState): string {
  return DEFAULT_ROUTE_BY_AUTH_STEP[authState] ?? ROUTES.LOGIN;
}
