import { injectable } from 'inversify';

export interface IFacebookPixelRepository {
  trackEvent(type: string, options?: Record<string, string>): void;
}

@injectable()
export class FacebookPixelRepository implements IFacebookPixelRepository {
  public trackEvent(type: string, options?: Record<string, string>): void {
    if ('fbq' in window && typeof window.fbq === 'function') {
      window.fbq('track', type, options);
    }
  }
}
