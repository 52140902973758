import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';

import { WorkspaceSeatsLimitError } from '@/features/common/workspace';
import { TeamMemberInviteGiftPlanLimitationError } from '../../../../../domain/errors/TeamMemberInviteGiftPlanLimitationError';

import styles from './styles.module.scss';

export const InviteErrorTip: FC<{
  error: WorkspaceSeatsLimitError | TeamMemberInviteGiftPlanLimitationError;
}> = ({ error }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'teamManagement.inviteDialog',
  });

  const getI18nKey = (): string => {
    switch (true) {
      case error instanceof WorkspaceSeatsLimitError:
        return 'invitePlanLimitationErrorTip.text';
      case error instanceof TeamMemberInviteGiftPlanLimitationError:
        return 'inviteGiftPlanLimitationErrorTip.text';
      default:
        return 'invitePlanLimitationErrorTip.text'; // WorkspaceSeatsLimitError
    }
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.icon}>
        <InfoOutlinedIcon />
      </Box>
      <Typography className={styles.text}>
        <Trans
          t={t}
          i18nKey={getI18nKey()}
          components={{
            b: <b />,
          }}
          values={{ count: error?.metadata?.limit || 1 }}
        />
      </Typography>
    </Box>
  );
};
