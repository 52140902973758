import { useCallback, useEffect, useState } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { useSnackbar } from 'notistack';
import { map } from 'rxjs';

import { useAccountUseCase } from '@/features/common/account';
import {
  InvalidPhoneCodeError,
  PhoneCodeSendPermanentRateLimitError,
  PhoneCodeSendRateLimitError,
} from '@/features/common/auth';
import { useAnalytics } from '@/features/system/analytics';

import { useAsyncExecutor } from '@/hooks';

import { useObservableResult } from '@/utils/rx';

import { useSignUpUseCase } from '../../../../hooks';

import { useSecondsLeft } from './hooks';

type ViewModel = () => {
  phone: string;
  secondsLeft: Nullable<number>;
  isVerifying: boolean;
  code: Nullable<string>;
  verificationState: VerificationState;
  hasCodeError: boolean;
  handleCodeResend: () => void;
  handleCodeVerify: () => Promise<void>;
  handleCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  codeResendDisabled: boolean;
  codeResendVisible: boolean;
  codeVerificationDisabled: boolean;
};

export type VerificationState =
  | 'loading' // loading state to determine if user can send code
  | 'idle' // user can send code and do not have any errors
  | 'resendTimer' // user send cod
  | 'invalidCode' // user entered invalid code
  | 'limitErrorTemporary' // user tried to send code too many times
  | 'limitErrorPermanent' // user is blocked to send code
  | 'unknown'; // somewhere we have fucked up

const codeResendVisibilityMap: Record<VerificationState, boolean> = {
  loading: false,
  idle: true,
  resendTimer: false,
  limitErrorTemporary: false,
  limitErrorPermanent: false,
  unknown: false,
  invalidCode: true,
};

const codeVerificationDisableMap: Record<VerificationState, boolean> = {
  loading: true,
  resendTimer: false,
  limitErrorTemporary: false,
  limitErrorPermanent: false,
  unknown: false,
  idle: false,
  invalidCode: true,
};

const hasCodeErrorMap: Record<VerificationState, boolean> = {
  loading: false,
  resendTimer: false,
  limitErrorTemporary: false,
  limitErrorPermanent: false,
  unknown: false,
  idle: false,
  invalidCode: true,
};

export const usePhoneVerificationViewModel: ViewModel = () => {
  const accountUseCase = useAccountUseCase();
  const signUpUseCase = useSignUpUseCase();
  const snackbar = useSnackbar();
  const { trackViewVerificationPhonePage } = useAnalytics();

  const { data: lockedUntil, isLoading: isLockedUntilLoading } = useObservableResult(() =>
    signUpUseCase.getSmsResendTime(),
  );

  const { data: phone } = useObservableResult(() =>
    accountUseCase.getAccount().pipe(
      map((account) => {
        if (account?.settings.phone) {
          const phoneNumberWithPlus = account?.settings.phone.startsWith('+')
            ? account?.settings.phone
            : `+${account?.settings.phone}`;

          const phoneNumber = parsePhoneNumber(phoneNumberWithPlus);

          return phoneNumber?.formatInternational() ?? '';
        }

        return '';
      }),
    ),
  );

  const [code, setCode] = useState<string>('');

  const [verificationState, setVerificationState] =
    useState<VerificationState>('loading');

  const secondsLeft = useSecondsLeft({
    lockedUntil,
  });

  const hasActiveTimer = secondsLeft > 0;

  const sendPhoneVerificationCode = useCallback(async (): Promise<void> => {
    try {
      const { nextAttemptIn, sent } = await signUpUseCase.sendPhoneVerificationCode();

      if (sent) {
        snackbar.enqueueSnackbar({
          variant: 'success',
          message: 'Verification code was sent',
        });
      }

      if (!nextAttemptIn) {
        setVerificationState('limitErrorPermanent');
      }
    } catch (error) {
      switch (true) {
        case error instanceof PhoneCodeSendRateLimitError:
          setVerificationState('limitErrorTemporary');
          break;
        case error instanceof PhoneCodeSendPermanentRateLimitError:
          setVerificationState('limitErrorPermanent');
          break;
        default:
          setVerificationState('unknown');
      }
    }
  }, []);

  const codeResendExecutor = useAsyncExecutor(sendPhoneVerificationCode);

  const onVerifyCode = useCallback(async (): Promise<void> => {
    if (!code) return;

    try {
      await signUpUseCase.verifySmsCode(code);
    } catch (error) {
      if (error instanceof InvalidPhoneCodeError) {
        setVerificationState('invalidCode');
      } else {
        setVerificationState('unknown');
      }
    }
  }, [code]);

  const codeVerifyExecutor = useAsyncExecutor(onVerifyCode);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);

    if (verificationState === 'invalidCode') {
      setVerificationState('idle');
    }
  };

  useEffect(() => {
    trackViewVerificationPhonePage();
  }, []);

  useEffect(() => {
    if (isLockedUntilLoading) return;

    if (hasActiveTimer) {
      setVerificationState('resendTimer');
    } else {
      setVerificationState('idle');
    }
  }, [hasActiveTimer, isLockedUntilLoading]);

  return {
    phone: phone ?? '',
    secondsLeft,
    isVerifying: codeVerifyExecutor.isLoading,
    code,
    hasCodeError: hasCodeErrorMap[verificationState],
    handleCodeResend: codeResendExecutor.asyncExecute,
    handleCodeVerify: codeVerifyExecutor.asyncExecute,
    handleCodeChange,
    verificationState,
    codeResendVisible: codeResendVisibilityMap[verificationState] && !hasActiveTimer,
    codeResendDisabled: codeResendExecutor.isLoading,
    codeVerificationDisabled:
      codeVerificationDisableMap[verificationState] ||
      !code ||
      codeVerifyExecutor.isLoading,
  };
};
