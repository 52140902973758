import { useInjection } from '@/ioc/ioc.react';
import { CUSTOMER_MESSAGING_TYPES } from '@/ioc/types';

import { useAnalytics } from '@/features/system/analytics';
import {
  CustomerMessagingEventKeys,
  ICustomerMessagingUseCase,
} from '@/features/system/CustomerIO';

export enum PaymentFailedReason {
  ServerError = 'Server error',
  CardDetails = 'Card details',
  EmptyFields = 'Empty fields',
}

type UsePaymentDetailsAnalytic = () => {
  paymentSuccess: (params: { name: string }) => void;
  paymentFailed: (name: string, reason: PaymentFailedReason) => void;
  clickSubmitPurchase: () => void;
  pageViewed: () => void;
};

export const usePaymentDetailsAnalytic: UsePaymentDetailsAnalytic = () => {
  const {
    trackPaymentDetailsPageView,
    trackPaymentSuccess,
    trackPaymentFailed,
    trackSubmitPurchaseClick,
  } = useAnalytics();

  const cmUseCase = useInjection<ICustomerMessagingUseCase>(
    CUSTOMER_MESSAGING_TYPES.CustomerMessagingUseCase,
  );

  return {
    pageViewed: (): void => {
      cmUseCase.page(CustomerMessagingEventKeys.VIEW_PAYMENT_DETAILS_PAGE);
      trackPaymentDetailsPageView();
    },
    paymentSuccess: (params: { name: string }): void => {
      cmUseCase.track(CustomerMessagingEventKeys.PURCHASE_SUCCESSFUL);
      trackPaymentSuccess(`${params.name}`);
    },
    paymentFailed: (name: string, reason: PaymentFailedReason): void => {
      cmUseCase.track(CustomerMessagingEventKeys.PAYMENT_FAILED);
      trackPaymentFailed(name, reason);
    },
    clickSubmitPurchase: (): void => {
      trackSubmitPurchaseClick();
    },
  };
};
