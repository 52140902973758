import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import { ENRICHMENT_TYPES } from '@/ioc/types';

import { IEnrichmentRepository, IEnrichmentUseCase } from './abstraction';
import { IEnrichmentEntity } from './entities';
import { EnrichmentSortSchema } from './types';

@injectable()
export class EnrichmentUseCase implements IEnrichmentUseCase {
  @inject(ENRICHMENT_TYPES.EnrichmentRepository)
  private readonly enrichmentRepository: IEnrichmentRepository;

  public getAllByParams(
    params: {
      name?: string;
      createdBy?: string;
    },
    sortSchema?: EnrichmentSortSchema,
  ): Observable<IEnrichmentEntity[]> {
    return this.enrichmentRepository.getAllByParams(params, sortSchema);
  }

  public download(uuid: string): Promise<string> {
    return this.enrichmentRepository.downloadReport(uuid);
  }

  public contactAdmin(): Promise<boolean> {
    return this.enrichmentRepository.contactAdmin();
  }
}
