import { useTagEntities, useTagEntitiesMetadata } from '@/features/common/tag';
import { useAnalytics } from '@/features/system/analytics';

import { useContactUseCase } from '../../../../hooks';

type AnchorType = 'default' | 'single' | 'multiple';

export const useTagsCellViewModel = ({ value, id }: { value: string[]; id: string }) => {
  const { data: selectedTags } = useTagEntities({ uuidIncludes: value || [] });
  const {
    data: { total },
  } = useTagEntitiesMetadata();
  const { trackContactAddTag } = useAnalytics();
  const contactUseCase = useContactUseCase();

  const getAnchorType = (): AnchorType => {
    if (selectedTags.length > 1) return 'multiple';

    if (selectedTags.length === 1) return 'single';

    return 'default';
  };

  const onApply = async (values: string[]): Promise<void> => {
    try {
      await contactUseCase.updateById(id, {
        tags: values,
      });
      trackContactAddTag();
    } catch (error) {
      throw error; // TODO need to handle error
    }
  };

  return {
    isNotAvailable: !total,
    anchorType: getAnchorType(),
    onApply,
    selectedTags,
  };
};
