import React, { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import ErrorIcon from '@/assets/icons/errorIcon.svg?react';

import { useFormattedSecondsLeft } from '../../hooks';
import type { VerificationState } from '../../usePhoneVerificationViewModel';

import styles from './styles.module.scss';

type Props = {
  verificationState: VerificationState;
  secondsLeft: Nullable<number>;
};

export const SentCodeErrorSection: React.FC<Props> = ({
  verificationState: contentType,
  secondsLeft,
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'phoneVerification' });

  switch (contentType) {
    case 'limitErrorTemporary':
      const formattedSecondsLeft = useFormattedSecondsLeft(secondsLeft ?? 0);
      return (
        <Wrapper>
          <Typography>
            {t('errors.limitTemporary')}{' '}
            {secondsLeft && <Box component="b">{formattedSecondsLeft}</Box>}
          </Typography>
        </Wrapper>
      );
    case 'limitErrorPermanent':
      return (
        <Wrapper>
          <Typography>
            <Trans
              t={t}
              i18nKey="errors.limitPermanent"
              components={{
                a: <a href={EXTERNAL_ROUTES.CONTACT_US} />,
              }}
            >
              You’ve tried this too many times. Please,{' '}
              <a href={EXTERNAL_ROUTES.CONTACT_US} target="_blank">
                contact support
              </a>
              .
            </Trans>
          </Typography>
        </Wrapper>
      );
    case 'unknown':
      return (
        <Wrapper>
          <Typography>
            <Trans
              t={t}
              i18nKey="errors.limitPermanent"
              components={{
                a: <a href={EXTERNAL_ROUTES.CONTACT_US} />,
              }}
            >
              Something went wrong. Please, try again later or{' '}
              <a href={EXTERNAL_ROUTES.CONTACT_US} target="_blank">
                contact support
              </a>
              .
            </Trans>
          </Typography>
        </Wrapper>
      );
    case 'invalidCode':
      return (
        <Wrapper>
          <Typography>{t('errors.invalidCode')}</Typography>
        </Wrapper>
      );
    default:
      return null;
  }
};

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.errorWrapper}>
        <Box>{children}</Box>
        <ErrorIcon />
      </Box>
    </Box>
  );
};
