import { FC, PropsWithChildren, useContext } from 'react';

import { useAnalytics } from '@/features/system/analytics';

import { DateSelect } from '@/components';

import { ContactsContext } from '../../context';

export const FilterByDateAction: FC<PropsWithChildren> = () => {
  const { filterByDate, setFilterByDate } = useContext(ContactsContext);
  const { trackFilterByDate } = useAnalytics();

  const handleDateChange = (value: string): void => {
    setFilterByDate(value);
    trackFilterByDate('contacts', value);
  };

  return <DateSelect value={filterByDate} onChange={handleDateChange} />;
};
