import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';

import { UserRole } from '@/features/common/account';

import { CustomSelectV2 } from '@/components';

import { EmailsAutocomplete } from './components/EmailsAutocomplete';
import { InviteWarningTip } from './components/InviteWarningTip';
import { useInviteModalViewModel } from './hooks/useInviteModalViewModel';
import { useTeamMemberRoleOptions } from './hooks/useTeamMemberRoleOptions';

import styles from './styles.module.scss';
import { InviteErrorTip } from './components/InviteErrorTip';

type InviteModalProps = {
  open: boolean;
  onClose(): void;
};

const ModalBody: React.FC<InviteModalProps> = ({ onClose }) => {
  const {
    handleSubmitForm,
    form,
    sendActionDisabled,
    inviteCharcheableWarning,
    invitePlanLimitationError,
    inviteGiftPlanLimitationError,
  } = useInviteModalViewModel();
  const { t } = useTranslation('settings');
  const options = useTeamMemberRoleOptions();
  const { emails = [], role } = form.watch();

  return (
    <Paper className={styles.Paper}>
      <Typography className={styles.Title}>
        {t('teamManagement.inviteDialog.title')}
      </Typography>
      <Typography className={styles.Subtitle}>
        {t('teamManagement.inviteDialog.subtitle')}
      </Typography>
      <Box className={styles.InputWrapper}>
        <EmailsAutocomplete
          values={emails}
          onChange={(values): void => {
            form.setValue('emails', values, { shouldValidate: true });
          }}
          error={form.formState.errors.emails}
        />
      </Box>
      <Box className={styles.InputWrapper}>
        <Typography>{t('teamManagement.inviteDialog.rolesInputTitle')}</Typography>
        <CustomSelectV2
          options={options}
          MenuProps={{
            ListProps: {
              classes: { root: styles.List },
            },
          }}
          value={role}
          onChange={(value: UserRole): void => {
            form.setValue('role', value);
          }}
        />
      </Box>
      {invitePlanLimitationError.visible ? (
        <InviteErrorTip {...invitePlanLimitationError.props} />
      ) : inviteGiftPlanLimitationError.visible ? (
        <InviteErrorTip {...inviteGiftPlanLimitationError.props} />
      ) : inviteCharcheableWarning.visible ? (
        <InviteWarningTip {...inviteCharcheableWarning.props} />
      ) : null}
      <Box className={styles.ButtonsWrapper}>
        <Button variant="text" color="info" onClick={onClose}>
          {t('teamManagement.inviteDialog.buttonCancel')}
        </Button>
        <Button
          variant="contained"
          color={'primary'}
          disabled={sendActionDisabled}
          onClick={(): void => {
            handleSubmitForm(onClose);
          }}
        >
          {t('teamManagement.inviteDialog.buttonSendInvite')}
        </Button>
      </Box>
    </Paper>
  );
};

export const InviteModal: React.FC<InviteModalProps> = (props) => {
  return (
    <Modal {...props} keepMounted={false}>
      <ModalBody {...props} />
    </Modal>
  );
};
