import { useContext } from 'react';

import { useAccount, UserRole } from '@/features/common/account';

import { AdminAccessDeniedModal, UserAccessDeniedModal } from './components';
import { EnrichmentUploadingContext } from '../../EnrichmentUploadingContext';

export const useEnrichmentAccessDeniedModalViewModel = () => {
  const { uploadAccessDeniedModal } = useContext(EnrichmentUploadingContext);
  const { account } = useAccount();

  return {
    CurrentModal:
      account?.role === UserRole.Admin || account?.role === UserRole.Owner
        ? AdminAccessDeniedModal
        : UserAccessDeniedModal,
    isOpen: uploadAccessDeniedModal.isOpen,
    close: uploadAccessDeniedModal.onClose,
  };
};
