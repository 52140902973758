import { inject, injectable } from 'inversify';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  switchMap,
} from 'rxjs';

import { AUTH_TYPES, EXTENSION_TYPES } from '@/ioc/types';

import { AuthState, IAuthUseCase } from '@/features/common/auth';
import { IExtensionMessageEntity, IExtensionUseCase } from '@/features/common/extension';

import { IExtensionAuthUseCase } from './abstractions';
import { LoggedInExtensionMessage, LoggedOutExtensionMessage } from './messages';

@injectable()
export class ExtensionAuthUseCase implements IExtensionAuthUseCase {
  @inject(AUTH_TYPES.AuthUseCase)
  private authUseCase: IAuthUseCase;

  @inject(EXTENSION_TYPES.ExtensionUseCase)
  private extensionUseCase: IExtensionUseCase;

  syncAuthWithExtension(): Observable<void> {
    return combineLatest({
      accessToken: this.authUseCase.getAccessToken(),
      authState: this.authUseCase.getAuthState(),
    }).pipe(
      filter(({ authState }) => authState !== AuthState.Identifying),
      map(({ accessToken, authState }) => {
        if (authState === AuthState.CanAccessSite) {
          return accessToken;
        }
      }),
      distinctUntilChanged(),
      switchMap((accessToken) => {
        const message = accessToken
          ? new LoggedInExtensionMessage(accessToken)
          : new LoggedOutExtensionMessage();
        return this.sendMessage(message);
      }),
    );
  }

  private async sendMessage(message: IExtensionMessageEntity): Promise<void> {
    try {
      await this.extensionUseCase.sendMessage(message);
    } catch {}
  }
}
