import { combineLatest, map } from 'rxjs';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { useAccount, useAccountUseCase, UserRole } from '@/features/common/account';
import { useExtensionUseCase } from '@/features/common/extension';
import { useIsFairUsageLimitReached } from '@/features/common/fairUsage';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useAnalytics, WalkthroughStep } from '@/features/system/analytics';

import { openInNewTab } from '@/utils/openInNewTab';
import { useObservableResult } from '@/utils/rx';

export const useDashboardHeaderViewModel = (): {
  accountFullName: string;
  isFairUsageLimitReached: boolean;
  onboardingStep: Nullable<'installExtension' | 'revealContact'>;
  onClickRevealContact: () => void;
  onClickInstallExtension: () => void;
} => {
  const { account } = useAccount();
  const accountUseCase = useAccountUseCase();
  const extensionUseCase = useExtensionUseCase();
  const onboardingUseCase = useOnboardingUseCase();

  const { isFairUsageLimitReached } = useIsFairUsageLimitReached();
  const { trackUserProductWalkthrough } = useAnalytics();

  const { data: onboardingStep } = useObservableResult(() =>
    combineLatest({
      isManager: accountUseCase
        .getAccount()
        .pipe(map((a) => a?.role === UserRole.Manager)),
      isExtensionInstalled: extensionUseCase.isExtensionInstalled(),
      isContactRevealed: onboardingUseCase
        .getStepStatus('revealContact')
        .pipe(map((status) => status === 'completed')),
    }).pipe(
      map(({ isManager, isExtensionInstalled, isContactRevealed }) => {
        if (isManager) return null;

        if (isExtensionInstalled && isContactRevealed) return null;

        if (isExtensionInstalled) return 'revealContact';

        return 'installExtension';
      }),
    ),
  );

  const onClickRevealContact = (): void => {
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_PROFILE_TO_REVEAL);
  };

  const onClickInstallExtension = (): void => {
    trackUserProductWalkthrough(WalkthroughStep.idle);
    onboardingUseCase.completeClickDownloadExtensionStep();
    openInNewTab(EXTERNAL_ROUTES.EXTENSION_URL);
  };

  return {
    accountFullName: account?.fullName || '',
    isFairUsageLimitReached,
    onboardingStep,
    onClickRevealContact,
    onClickInstallExtension,
  };
};
