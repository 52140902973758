import { ProductMetadataBuilder } from './ProductMetadataBuilder';

export class ProProductMetadataBuilder extends ProductMetadataBuilder {
  getFeatures(): { title: string; items: string[] } {
    const items = ['Data exports'];

    if (this.getIsFeatureEnabled('team_member_management')) {
      items.push('Invite, collaborate with, and manage team members');
    }

    if (this.getIsFeatureEnabled('bulk_save')) {
      items.push('Bulk enrichment & bulk save');
    }

    return {
      title: 'What’s included:',
      items,
    };
  }
  getDescription(): string {
    return 'The tools your team needs to keep on growing';
  }
  getIsCreditCardRequired(): boolean {
    return true;
  }
  getIsMostPopular(): boolean {
    return false;
  }
}
