import { inject, injectable, postConstruct } from 'inversify';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
} from 'rxjs';

import { APP_CONFIG_TYPES, AUTH_TYPES, LEADER_ELECTION_TYPES } from '@/ioc/types';

import type { ILeaderElectionRepository } from '@/features/system/leaderElection';

import type { IAuthRepository } from '../../auth';
import { IExtensionMessageEntity, PingExtensionMessage } from '../domain';

import type { IExtensionRepository } from './abstractions';

import type { AppConfig } from '@/config';

@injectable()
export class ExtensionRepository implements IExtensionRepository {
  @inject(APP_CONFIG_TYPES.AppConfig)
  private readonly appConfig: AppConfig;

  private isExtensionInstalled$ = new BehaviorSubject<boolean>(false);
  private extensionInfo$ = new BehaviorSubject<Nullable<chrome.management.ExtensionInfo>>(
    null,
  );

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leader: ILeaderElectionRepository;

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @postConstruct()
  init(): void {
    this.getExtensionCheckTrigger().subscribe(() => {
      this.sendMessage(new PingExtensionMessage())
        .then((message) => {
          this.isExtensionInstalled$.next(true);

          if (typeof message === 'object') {
            this.extensionInfo$.next(message as chrome.management.ExtensionInfo);
          }
        })
        .catch(() => {
          this.isExtensionInstalled$.next(false);
        });
    });
  }

  private getExtensionCheckTrigger(): Observable<boolean> {
    return combineLatest([
      this.leader.getIsLeaderSubject(),
      this.authRepository.getAuthStatus(),
    ]).pipe(
      filter(([isLeader]) => isLeader),
      distinctUntilChanged(),
      map(() => true),
    );
  }

  isExtensionInstalled(): Observable<boolean> {
    return this.isExtensionInstalled$.asObservable();
  }

  sendMessage(message: IExtensionMessageEntity): Promise<unknown> {
    return (
      window?.chrome?.runtime?.sendMessage(this.appConfig.extension.id, message) ??
      Promise.reject(new Error('Extension is not installed'))
    );
  }

  getExtensionInfo(): Observable<Nullable<chrome.management.ExtensionInfo>> {
    return this.extensionInfo$.asObservable();
  }
}
