import { injectable } from 'inversify';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

type SaveMap = Record<string, unknown>;

export interface IStorageRepository {
  save: (map: SaveMap) => void;
  clear: () => void;
  get$: <T = unknown>(
    key: string,
    validator?: ValueTypeValidator<T>,
  ) => Observable<Nullable<T>>;
  get: <T = unknown>(key: string, validator?: ValueTypeValidator<T>) => Nullable<T>;
  delete: (key: string) => void;
}

@injectable()
export class StorageRepository implements IStorageRepository {
  private storage$ = new BehaviorSubject<Map<string, unknown>>(new Map());

  constructor() {
    this.initDefaults();
    this.syncLocalStorages();
  }

  private syncLocalStorages = (): void => {
    window.addEventListener(
      'storage',
      () => {
        this.initDefaults();
      },
      false,
    );
  };

  private initDefaults = (): void => {
    const initValue = new Map();

    Object.entries(localStorage).forEach(([key, value]) => {
      initValue.set(key, this.parseValue(value));
    });

    this.storage$.next(initValue);
  };

  clear = (): void => {
    const storage = this.storage$.getValue();
    localStorage.clear();
    storage.clear();
    this.storage$.next(storage);
  };

  save = (keyValue: SaveMap): void => {
    const storage = this.storage$.getValue();

    Object.entries(keyValue).forEach(([key, value]) => {
      localStorage.setItem(key, JSON.stringify(value));
      storage.set(key, value);
    });

    this.storage$.next(storage);
  };

  get$ = <T>(key: string): Observable<T> => {
    return this.storage$.pipe(
      map((storage) => {
        return storage.get(key) as T;
      }),
      distinctUntilChanged(
        (previous, current) => JSON.stringify(previous) === JSON.stringify(current),
      ),
    );
  };

  get = <T>(key: string): Nullable<T> => {
    const storageMap = this.storage$.getValue();
    return storageMap.get(key) as T;
  };

  delete = (key: string): void => {
    localStorage.removeItem(key);
    const storage = this.storage$.getValue();
    storage.delete(key);
    this.storage$.next(storage);
  };

  private parseValue<T = unknown>(
    value: string | null,
    validateValue: ValueTypeValidator<T> = (
      valueToValidate: unknown,
    ): valueToValidate is T => true,
  ): Nullable<T> {
    if (!value) {
      return null;
    }

    try {
      const parsed = JSON.parse(value);

      if (validateValue(parsed)) {
        return parsed;
      }

      return null;
    } catch (e) {
      return null;
    }
  }
}
